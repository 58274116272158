import { ApiSails } from "./config/ApisailsRequest";
import { setActiveUser } from '../store/user/actions';
import moment from 'moment';
import store from "../store";

export default {
    getUsers(from = moment().toISOString(), to = moment().subtract(1, 'months').toISOString()) {
        const params =  {
          where: {"$and":[{"createdAt":{"$gte":from}}, {"createdAt":{"$lte":to}}]}
        }
        return ApiSails.get("/userpro", params);
    },

    login(params) {
        const response = ApiSails.post("/userpro/login", params)
        .then((res) => {
            store.dispatch(setActiveUser(res)); 
        })
        return response;
    },
}
