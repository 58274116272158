import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import './page.scss'
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    paddingLeft: 290,
    paddingRight: 30
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    marginBottom: 30
  },
}));

const Page = forwardRef(({
  children,
  title = '',
  ...rest
}, ref) => {
  const classes = useStyles();
  return (
      <div
        ref={ref}
        {...rest}
      >
        
        <div 
        // className={classes.wrapper}
        className="component-page-wrapper"
        >
          {/* <div className={classes.contentContainer}>
            <div className={classes.content}> */}
              {children}
            {/* </div>
          </div> */}
        </div>
      </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
