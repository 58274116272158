const setActiveUser = data => ({
  type: "SET_ACTIVE_USER",
  data
});

const resetUserData = () => ({
  type: "RESET_USER_DATA",
  undefined
});

export const mutation = {
  setActiveUser,
  resetUserData
};
  