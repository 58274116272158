import React from 'react'
import './sendingblueview.scss'
import { clearLocal } from '../../helpers/utils';
import SendingblueService from '../../services/SendingblueService';

const SendingblueEventCard = ({ title, picture, categories, date, datefin }) => {
    const DATE = new Date(date)
    const DATE_FIN = new Date(datefin)
    return (
        <div className="sendingblue-event-card">
            <img src={picture} alt="" />
            <span>
                <h6>{title}</h6>
                <span>{categories.map(a => a.name).join(", ")}</span>
                <br />
                <span>
                    {DATE.getDate()}/{DATE.getMonth()}/{DATE.getFullYear().toString().substr(2)}
                    {" - "}
                    {DATE_FIN.getDate()}/{DATE_FIN.getMonth()}/{DATE_FIN.getFullYear().toString().substr(2)}
                </span>
            </span>
        </div>
    )
}

const SendingBlueView = ({ events, onClose, contactList, templates }) => {
    contactList = contactList || []
    templates = templates || []
    const submit = (e) => {
        e.preventDefault();
        let values = [0, 1, 2, 3, 4, 5, 6, 7, 8].map(a => e.target[a].value)
        // console.log(values)
        const [
            campaign_name,
            sender_name,
            sender_email,
            campaign_subject,
            reply_to,
            scheduled_at_data,
            scheduled_at_time,
            list_id,
            template_id,
        ] = values
        const scheduled_at = new Date(scheduled_at_data + "T" + scheduled_at_time).toISOString()
        const events_id = events.map(a => a.id)
        SendingblueService.send({
            campaign_name,
            sender_name,
            sender_email,
            campaign_subject,
            reply_to,
            scheduled_at,
            list_id: parseInt(list_id),
            template_id: parseInt(template_id),
            events_id
        })
            .then(onClose)
            .catch(err => {
                console.error(err)
                window.alert("There was an issue sending your campaign.")
            })
        return

    }
    return (
        <div className="sendingblue-view" onClick={onClose}>
            <div onClick={e => e.stopPropagation()}>
                <h2>SendingBlue Campaign</h2>
                <div className="sendingblue-selected-events">
                    <h6>Events</h6>
                    <div>
                        {events.map(a => {
                            const { id, title, picture, categories, date, datefin } = a
                            return (<SendingblueEventCard key={id} title={title} picture={picture} categories={categories} date={date} datefin={datefin} />)
                        })}
                    </div>
                </div>
                <form onSubmit={submit}>
                    <div>
                        <label htmlFor="name">Campaign name</label>
                        <input type="text" name="name" placeholder="Campaign name" />
                    </div>
                    <div>
                        <label htmlFor="sender">Sender name</label>
                        <input type="text" name="sender" placeholder="Sender name" />
                    </div>
                    <div>
                        <label htmlFor="mail">Sender Email Address</label>
                        <input type="text" name="mail" placeholder="Sender Email Address" />
                    </div>
                    <div>
                        <label htmlFor="subject">Campaign subject</label>
                        <input type="text" name="subject" placeholder="Campaign subject" />
                    </div>
                    <div>
                        <label htmlFor="reply">Reply to</label>
                        <input type="text" name="reply" placeholder="Reply to" />
                    </div>
                    <div>
                        <label htmlFor="schedule">Schedule at</label>
                        <span>
                            <input type="date" name="schedule-date" />
                            <input type="time" name="schedule-time" />
                        </span>
                    </div>
                    <div className="bottom-form-sendingblue">
                        <select name="segment">
                            <option value="">Contact segment</option>
                            {contactList.map(a => {
                                return <option ket={a.id} value={a.id}>{a.id + " - " + a.name}</option>
                            })}
                        </select>
                        <select name="template">
                            <option value="">Template</option>
                            {templates.map(a => {
                                return <option ket={a.id} value={a.id}>{a.id + " - " + a.name}</option>
                            })}
                        </select>
                        <button type="submit">Send Campaign</button>
                    </div>
                </form>
            </div>
            { clearLocal()}
        </div >)
}

export default SendingBlueView;