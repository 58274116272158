import { ApiMongo } from './config/ApiMongoRequest';
import { ApiSails } from './config/ApisailsRequest';
import store from '../store'
import Axios from 'axios';

export default {
  getCrawledEventNumber(filters) {
    return ApiMongo.get('/events/count' + ((filters) ? '?search_filter=' + JSON.stringify(filters) : ''), null);
  },

  // let search = "{\"$and\":[{\"$or\":[{\"title\":{\"$iLike\":\"%" + title + "%\"}},{\"place\":{\"$iLike\":\"%" + title + "%\"}}]},{\"datefin\":{\"$gte\":\"2021-02-05T14:27:20.163Z\"}}]}&order=[[\"datefin\",\"ASC\"]]&page=1&perPage=10";
  //   JSON.stringify(search)
  //   console.log(search)
  //   const encoded = encodeURI(search)

  getFilteredCrawledEvents(filters) {

    // let test = "where={"
    // let search = "where={\"$and\":[{\"$or\":[{\"title\":{\"$iLike\":\"%" + filters.searchByName + "%\"}},{\"place\":{\"$iLike\":\"%" + filters.searchByName + "%\"}}]},{\"datefin\":{\"$gte\":\"2021-02-05T14:27:20.163Z\"}}]}";
    // const encoded = encodeURI(search)
    // let url = filters.searchByName ? encoded : "";

    let page = filters.page ? parseInt(filters.page) : 1;
    let perPage = filters.perPage ? parseInt(filters.perPage) : 10;
    let ff = { ...filters }


    delete ff.order
    let metaData = {}

    return ApiMongo.get('/events?page=' + page + '&perPage=' + perPage + ((filters) ? '&search_filter=' + JSON.stringify(ff) : '')
      + '&order=[{date:' + (filters.order ? "ASC" : "DESC") + '}, {name:ASC}]', {}).then(response => {
        metaData = response.metaData;
        return response.rows
      })
      .then(rows => {
        return Promise.all(rows.map(event => ApiMongo.formatEvent(event)))
      }).then(rows => {
        return { metaData, rows }
      })
      .catch(err => {
        console.log(err)
      })
  },

  getOrigins(olds) {
    return ApiSails.get('/event/getOrigins' + ((olds) ? '?olds=' + olds : ''), {});
  },

  getCrawlers(filter) {
    return ApiMongo.get('/events/crawlers' + ((filter) ? '?where=' + JSON.stringify(filter) : ''), {});
  },


  validEvents(event) {
    console.log(event)
    const events = Array.isArray(event) ? event : [event]
    return Promise.all(events.map(event => {
      event.date += "Z"
      event.datefin += "Z"
      console.log(events)
      return ApiMongo.patch('/events/validate', { event: { ...event, _id: event.id, ...event.coordinate, categories: event.categories.map(a=>a.id) } })
        // .then(() => this.deleteMongoEvent(event.id))
    }))
  },

  deleteMongoEvent(event) {
    if (Array.isArray(event)) {
      event.map(event => ApiMongo.patch('/events/' + event.id + '/markDeleted', {}))
    }
    else {
      ApiMongo.patch('/events/' + (event ? event : event.id) + '/markDeleted', {})
    }
  },

  getSailsEventsCount(company) {
    if (!company) {
      return ApiSails.get("/event?perPage=1", {})
    }
    const id = store.getState().userData.company.id
    if (typeof id === "string") {
      return ApiSails.get("/event?perPage=1&company=" + id, {})
    }
    else throw new Error("Invalid Id")
  },

  getSailsEvents(filters, company) {

    // let search = "&where={\"$and\":[{\"$or\":[{\"title\":{\"$iLike\":\"%" + filters.searchByName + "%\"}},{\"place\":{\"$iLike\":\"%" + filters.searchByName + "%\"}}]},{\"datefin\":{\"$gte\":\"2021-02-05T14:27:20.163Z\"}}]}";
    // const encoded = encodeURI(search)
    // debugger
    // console.log(filters)

    let query = "";
    company && (query += "&company=" + store.getState().userData.company.id)
    query += "&page=" + (filters.page || "1")
    query += "&perPage=" + (filters.perPage || "10")
    if (filters.ancienneté == 2 || filters.ancienneté == 1) query += "&olds=" + (filters.ancienneté - 1)
    let populate = ["company_events", "coordinate", "categories", "tags"]
    if (filters.cities && filters.cities.lng && filters.cities.lat) {
      query += "&lat=" + filters.cities.lat + "&lng=" + filters.cities.lng
      query += "&distance=" + (filters.distance || "20")
    }

    if (filters.origine || filters.searchByName ) {
      let whereOrigin = null;
      if (filters.origine) {
        whereOrigin = { origin: filters.origine };
      }
      let whereName = null;
      if (filters.searchByName) {
        whereName = {"$or":[{"title":{"$iLike":"%" + filters.searchByName + "%"}},{"place":{"$iLike":"%" + filters.searchByName + "%"}}]};
      }
      if (whereOrigin && whereName) {
        query += "&where=" + encodeURI(JSON.stringify({"$and": [whereOrigin, whereName]}));
      } else if (whereOrigin) {
        query += "&where=" + encodeURI(JSON.stringify(whereOrigin));
      } else if (whereName) {
        query += "&where=" + encodeURI(JSON.stringify(whereName));
      }
    }

    let cat = Array.isArray(filters.categories) ? filters.categories.filter(a => a) : []
    cat && cat.length && (query += "&categories=" + JSON.stringify(cat))
    if (filters.inevitable) query += '&inevitable=' + filters.inevitable
    query += '&order=[["date","' + (filters.ordre - 1 ? "ASC" : "DESC") + '"]]'
    query += "&populate=" + JSON.stringify(populate)
    return ApiSails.get('/event' + (query.length && query.replace("&", "?")), {}).then(response => {
      let formatted = { metaData: response.metaData };
      formatted.rows = response.rows.map(event => ApiSails.formatEvent(event))
      console.log(formatted)
      return formatted
    });
  },
  addEvent,
  updateEvent,
  putSailsInevitable(eventId, rate) {
    console.log("PUT SAILS", typeof eventId !== 'string' || !(rate >= 0 && rate <= 5))
    if (typeof eventId !== 'string' || !(rate >= 0 && rate <= 5)) return
    const body = {
      inevitable: !!rate,
      // inevitableRate: rate === 0 ? null : rate
      inevitableRate: rate
    }
    return ApiSails.get('/event/' + eventId).then(ev => {
      console.log("PUT SAILS", ev)
      if (!ev.id) throw new Error("Look at response here man")
      return ApiSails.put('/event/' + eventId, Object.assign(ev, body))
    }).then(res => {
      console.log(res)
      return true
    }).catch(err => { console.error(err); throw err })
  },

  putSailsPublished(eventId, bool) {
    return ApiSails.put('/event/' + eventId, { published: bool })
  },

  putSailsChat(eventId, bool) {
    console.log(bool)
    return ApiSails.put('/event/' + eventId, { chatbot: bool })
  },
  deleteSelectedEvents
}

function addEvent(params) {
  return new Promise((resolve, reject) => {
    let theEvent;
    insertEvent(params)
      .then(res => {
        theEvent = { ...res };
        params.id = res.id;
        return insertPicture(params);
      })
      .then(() => insertCoordinates(params))
      .then(() => insertEventCategories(params))
      .then(() => insertCompanyEvent(params))
      .then(() => {
        if (params.hasOwnProperty('tags')) {
          return insertEventTags(params);
        } else {
          return null;
        }
      })
      .then(() => resolve(theEvent))
      .catch(err => reject(err))
  })
}

function deleteSailsEvent(eventId) {//Event Service
  // return APIService.request('DELETE', '/crawledEvents/' /*?eventId='*/ + eventId, {},true);
  return ApiSails.deleted('/event/' + eventId, {});
}

function deleteSelectedEvents(data) {
  console.log(data)
  data.map(
    row => ApiSails.deleted('/event/' + row.id,
    )
  )
}

function insertEvent(params) {
  return new Promise((resolve, reject) => {
    let theUrl = '/event';
    let body = {
      site: params.site,
      title: params.title,
      place: params.place,
      description: params.description,
      date: params.date,
      datefin: params.datefin,
      url_ticketing: params.url_ticketing ? params.url_ticketing : null,
      published: true,
      chatbot: true,
      origin: params.origin ? params.origin : null
    }
    resolve(ApiSails.post(theUrl,
      body
    ))
  })
}

function insertPicture(params) {
  return new Promise((resolve, reject) => {
    let theUrl = '/picture';
    let body = {
      event: params.id,
      file: params.picture
    }
    ApiSails.post(theUrl,
      body
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
  })
}

function insertCoordinates(params) {
  return new Promise((resolve, reject) => {
    let theUrl = '/coordinate';
    let body = {
      event: params.id,
      address: params.address,
      lat: params.lat,
      lng: params.lng
    }
    ApiSails.post(theUrl,
      body
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      })
  })
}

function insertEventCategories(params) {
  return Promise.all(
    params.categories.map(
      category => ApiSails.post('/eventcategory',
        {
          event_id: params.id,
          category_id: category
        }
      )
    )
  )

}

function createTagList(tags) {
  return new Promise((resolve, reject) => {
    let theUrl = '/tag/createMultiple';
    const body = {
      tags: tags
    };
    // ApiSails({
    //   url: theUrl,
    //   method: "POST",
    //   headers: Object.assign({}, headers),
    //   data: body
    // })
    resolve(
      ApiSails.post(theUrl,
        body
      )
    )
  });
}

function insertEventTags(params) {
  return new Promise((resolve, reject) => {
    createTagList(params.tags)
      .then((result) => {

        let tagsIds = result.map(x => x.id);
        let theUrl = '/event/' + params.id + "/setTags";
        const body = {
          tags: tagsIds
        }
        return ApiSails.post(theUrl,
          body
        )
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch(error => {
        console.log("insertEventTags:", error)
        reject(error);
      })
  });
}

function insertCompanyEvent(params) {
  return new Promise((resolve, reject) => {
    let theUrl = '/companyEvent';
    const body = {
      company_id: store.getState().userData.company.id,
      event_id: params.id,
      user_id: store.getState().userData.id
    };
    ApiSails.post(theUrl,
      body
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch(err => {
        console.log("Error in createTag:", err)
        reject(err);
      })
  })
}

function updateCategoriesEvent(id, data) {
  return ApiSails.put('/eventcategory/' + id, data)
}


function getEventCategories(evtId) {
  return ApiSails.get('/eventcategory?where={"event_id":"' + evtId + '"}&perPage=50&page=1')
}

function deleteCategoriesEvent(data) {
  return Promise.all(data.map(row => ApiSails.deleted('/eventcategory/' + row.id))).catch(console.error)
}

function updateEvent(params) {
  return ApiSails.put('/event/' + params.id, params)
    .then(r => getEventCategories(params.id))
    .then(r => deleteCategoriesEvent(r.rows))
    .then(r => insertEventCategories(params))
    .then(r => updatePicture(params))
    .then(r => updateCoordinate(params))
    .then(r => insertEventTags(params))
    .catch(err => {
      console.log("%cReturn An Error When Updating Coordinates", "color: red; font-size: 11pt")
      throw err
    })
}

function updateCoordinate(params) {
  console.log(params)
  const objectToSend = {
    address: params.address,
    lat: params.lat || params.coordinate && params.coordinate.lat,
    lng: params.lng || params.coordinate && params.coordinate.lng,
  };

  return ApiSails.put('/coordinate/' + params.coordinateId , objectToSend)

}

function updatePicture(params) {
  return ApiSails.get(`/picture?where={"event":"${params.id}"}&filter=["id"]`).then(
    res=> ApiSails.put(`/picture/${res.rows[0].id}`, { file: params.picture, pictureId: res.rows[0].id })
    )
  // return ApiSails.put(`/picture?where={"event":"${params.id}"}`, { file: params.picture, pictureId: params.pictureId })
  // return ApiSails.put('/picture/' + params.pictureId, { file: params.picture, pictureId: params.pictureId })
}
