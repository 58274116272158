import { mutation } from "./mutations";

const handleError = err => {
  console.error("Error in apiToken action : ", err);
};

export const setActiveUser = data => dispatch => {
  try {
    dispatch(mutation.setActiveUser(data));
  } catch (err) {
    handleError(err);
  }
};