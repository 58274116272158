import React, { Component } from 'react';
import { TagsService } from '../services/TagsService';
import { Plus } from 'react-feather';

export default class Tags extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchMin: 1,
      inputMin: 3,
      tags: this.props.currentEvent.tags ? this.props.currentEvent.tags : [],
      foundTags: [],
    }
    this.value = ""
    this.removeTag = this.removeTag.bind(this);
    this.inputKeyUp = this.inputKeyUp.bind(this);
    this.selectTag = this.selectTag.bind(this);
    this.changeTags = this.changeTags.bind(this);
  }

  removeTag = (i) => {
    const newTags = [ ...this.state.tags ];
    newTags.splice(i, 1);
    this.changeTags(newTags)
  }

  selectTag = (e) => {
    if (this.state.tags.find(tag => tag.toLowerCase() === e.toLowerCase())) {
      return;
    }
    const tags = [...this.state.tags, e]
    this.changeTags(tags)
    this.setState({foundTags: null}) // Clear the suggestions field when user select one tag
    this.inputField.value = null;
  }

  changeTags = (newTagsList) => {
    let taggedEvent = this.props.currentEvent
    this.setState(prevState => ({
      ...prevState,
      tags : newTagsList
    }))
    taggedEvent.tags = newTagsList;
    this.props.updateEvent(prevState => ({
      ...prevState,
      currentEvent: taggedEvent,
    }))
  }

  inputKeyUp = e => {
    const input = e.target.value;
    input.length >= this.state.searchMin && TagsService.findSimilar(input)
      .then(result => {
          this.setState({foundTags: result.filter(x =>!(this.state.tags || []).includes(x.name)) })
      })
      .catch (error => {
      });
  }
  
  onEnterOrSupp = e =>{
    const input = e.target.value;
    if (e.key === 'Enter' && input && input.length >= this.state.inputMin) {
      if (this.state.tags.find(tag => tag.toLowerCase() === input.toLowerCase())) {
        return;
      }
      let newTags = [...this.state.tags, input]
      this.changeTags(newTags)
      this.inputField.value = null;
      TagsService.createTag(input)
    } else if (e.key === 'Backspace' && !this.value) {
      this.removeTag(this.state.tags.length - 1)// : this.setState({foundTags : []})
    } 
  }
  render() {
    const { tags, foundTags } = this.state;
    return (
      <div className="input-tag">
        <ul className="input-tag_tags">
          {tags.map((tag, i) => (
            <li key={tag}>
              {tag}
              <button type="button" onClick={() => {this.removeTag(i)}}>+</button>
            </li>
          ))}
          <li className="input-tag_tags_input">
            <input type="text" onKeyUp={this.onEnterOrSupp} onKeyDown={e=>this.value=e.target.value} onInput={this.inputKeyUp} ref={c => {this.inputField = c}} />
          </li>
        </ul>
        <div className="d-flex">
            { this.state.foundTags && foundTags.map((foundTag, i) =>
              <div className="btn btn-outline-dark m-1 d-flex align-items-center" key={i} onClick={()=>this.selectTag(foundTag.name)}>
                <Plus size={18} className="pr-1"/>
                <span>
                        {foundTag.name}
                </span>
              </div>
            )}
        </div>
      </div>
    );
  }
}
