import store from '../store';
import { mutation as Token } from '../store/apiToken/mutations';
import { mutation as User } from '../store/user/mutations';

const TOKEN_KEY = 'jwt'; // which token use ?

export function isLogin() {

    const rstore = store.getState();

    if (rstore.userData.company) {
        let company = rstore.userData.company.name
        let role = rstore.userData.roles.slice(-8, -3)
        if (company === "Time N'Joy" && "TNJ Test" && role === "ADMIN") {
            return true
        }
    }
    else if (sessionStorage.getItem(TOKEN_KEY)) {
        return true
    }
}


export function logIn(history) {
    const rstore = store.getState();
    console.log(rstore)

    if (rstore.userData.company.name === "Time N'Joy" && "TNJ Test") {
        sessionStorage.setItem(TOKEN_KEY, 'true')
    }
    else {
        sessionStorage.setItem(TOKEN_KEY, 'false')
        alert("Vous n'êtes pas un admin de Time N'Joy")
    }
    history.push('/')
}

export function logOut(history) {
    sessionStorage.removeItem(TOKEN_KEY)
    sessionStorage.removeItem("store");
    store.dispatch(Token.resetApitoken())
    store.dispatch(User.resetUserData())
    history.push('/')
}

// Clear localstorage when window is closed - USED IN APP.JS
// You may need to disable it if you want to keep session active
// after closing browser or refresh
export function clearLocal() {
    window.onbeforeunload = function () {
        //localStorage.removeItem(TOKEN_KEY);
        return '';
    }
}