import React, { useRef } from "react";
import moment from "moment";
import Jimp from "jimp";

import { Snackbar } from "@material-ui/core";

import "./EventValidation.css";

import Page from "../../components/Page/Page";
import TopBar from "../../components/TopBar";
import NavBar from "../../components/NavBar/index";
import EventList from "../../components/EventList";
import EventFocusView from "../../components/EventFocusView";
import CategoriesService from "../../services/CategoriesService";
import PicturesService from "../../services/PicturesService";
import EventsService from "../../services/EventsService";

import MuiAlert from "@material-ui/lab/Alert";
import AuthService from "../../services/AuthService";
import * as Save from '../../store/events/actions'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async'
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ListItemAvatar,
} from "@material-ui/core"
import Rating from '@material-ui/lab/Rating'
import InfoIcon from '@material-ui/icons/Info'
import PropTypes from 'prop-types'
import 'moment/locale/fr'
import store from '../../store'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DEFAULT_NUMBER_PER_PAGE = 10;

class EventValidation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingEvents: true,

      // events: [],
      // shownEvents: [],
      // currentEvent: null,
      // selectedEvents : [],
      totalEvents: 0,
      totalEventsCount: 0,

      // picture: null,
      // pictureCropped: null,
      // date: {start: '', end: ''},
      categoriesList: null,

      // showFilters: true,
      // activeFilter: false,
      // coordinatesFilter: { lng: 0.0, lat: 0.0 },
      // distanceFilter: 50,
      // noteFilter: 6,
      // noteFilterMode: 0,
      // categoryFilter: {value: '', children: "Toutes"},
      // oldFilter: 2,
      // orderFilter: 0,

      // indexEventPass: 0,

      // snackbarMessage: "",
      // snackbarOpen: false,

      // page: 1,
      // perPage: DEFAULT_NUMBER_PER_PAGE,
      // crawlers: [],
      // crawlerFilter: "TOUS",
      // cityValue: "TOUTES"
    };
    this.focusMode = false;
    this.setState = this.setState.bind(this);
    this._changeEvent = this._changeEvent.bind(this);
    this._updateCategoriesSelect = this._updateCategoriesSelect.bind(this);
    this._deleteEvent = this._deleteEvent.bind(this);
    this._seePrevNextEvent = this._seePrevNextEvent.bind(this);
    this._validEvent = this._validEvent.bind(this);
    this._updateAddress = this._updateAddress.bind(this);
  }

  componentDidMount() {
    AuthService.check()
      .then(() => this._fetchCrawlerNames())
      .then(() => this._fetchCategories())
    // .then(()=>this._fetchTotalEvents());
  }

  // _getFilterObject = (page = 1, perPage = this.state.perPage) => {
  //   return {
  //     ...this.state.coordinatesFilter,
  //     dist: this.state.distanceFilter,
  //     note: this.state.noteFilter,
  //     noteMode: this.state.noteFilterMode,
  //     page: page.toString(),
  //     perPage: perPage.toString(),
  //     crawler: this.state.crawlerFilter,
  //     categories: [this.state.categoryFilter ? this.state.categoryFilter.value : /*null*/''],
  //     archived: false
  //   };
  // };

  // async _fetchTotalEvents() {
  //   try {
  //     const totalEvents = await EventsService.getCrawledEventNumber(
  //       this._getFilterObject()
  //     );
  //     this.setState({ totalEvents: totalEvents });
  //   } catch (err) {
  //   }
  // }

  async _fetchCrawlerNames() {
    try {
      const names = await EventsService.getCrawlers({ archived: { $exists: false } })

      this.setState({ crawlers: names });
    } catch (err) {
    }
  }

  async _fetchCategories() {
    try {
      const categories = await CategoriesService.getCategories();
      this.setState({ categoriesList: categories.rows.map(a => ({ name: a.name, value: a.id })) });
    } catch (err) {
    }
  }

  _changeDateWithEvent() {
    if (this.state.currentEvent) {
      let date = { start: [], end: [] }
      let theEvent = this.state.currentEvent;

      if (this.state.currentEvent.date) {
        if (this.state.currentEvent.date.date) {
          date.start.push(this.state.currentEvent.date.date);
          date.start.push(this.state.currentEvent.date.time);
        } else if (moment(this.state.currentEvent.date).isValid()) {
          date.start.push(moment(this.state.currentEvent.date).format("YYYY-MM-DD"))
          date.start.push(moment(this.state.currentEvent.date).format("HH:mm"))
        }
        theEvent.date = {
          date: date.start[0],
          time: date.start[1]
        }
      } else {
        theEvent.date = null;
      }

      if (this.state.currentEvent.datefin) {
        if (this.state.currentEvent.datefin.date) {
          date.end.push(this.state.currentEvent.datefin.date);
          date.end.push(this.state.currentEvent.datefin.time);
        } else if (this.state.currentEvent.datefin &&
          moment(this.state.currentEvent.datefin).isValid()) {
          date.end.push(moment(this.state.currentEvent.datefin).format("YYYY-MM-DD"))
          date.end.push(moment(this.state.currentEvent.datefin).format("HH:mm"))
        }
        theEvent.datefin = {
          date: date.end[0],
          time: date.end[1]
        }
      } else {
        theEvent.datefin = null;
      }

      this.setState(
        {
          date,
          currentEvent: theEvent
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  openSnackbar(msg = "", open = true) {
    this.setState({ snackbarMessage: msg, snackbarOpen: open });
  }
  openErrorSnackbar(msg = "", open = true) {
    this.setState({ snackbarMessage: msg, snackbarOpen: open, severity: "error" });
  }
  openSuccessSnackbar(msg = "", open = true) {
    this.setState({ snackbarMessage: msg, snackbarOpen: open, severity: "success" });
  }
  _updateCategoriesSelect(e) {
    let options = e.target.options;
    let value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    let currentEvent = this.state.currentEvent;
    currentEvent.categories = value;
    this.setState({ currentEvent });
  }

  _updateField = field => (e) => {
    let currentEvent = this.state.currentEvent;
    let value = e.target.value;

    if (field.match(/^date/g)) {

      if (field === "date-date") {
        if (currentEvent.date.date) {
          currentEvent.date.date = value
        } else {
          currentEvent.date = value + currentEvent.date.slice(10)
        }

      } else if (field === "date-time") {
        if (currentEvent.date.time) {
          currentEvent.date.time = value
        } else {
          currentEvent.date = currentEvent.date.replace(/T\d\d:\d\d/, "T" + value)
          console.log(currentEvent.date)
        }

      } else if (field === "datefin-date") {
        if (currentEvent.datefin.date) {
          currentEvent.datefin.date = value
        } else {
          currentEvent.datefin = value + currentEvent.datefin.slice(10)
        }
      } else if (field === "datefin-time") {
        if (currentEvent.datefin.time) {
          currentEvent.datefin.time = value
        } else {
          currentEvent.datefin = currentEvent.datefin.replace(/T\d\d:\d\d/, "T" + value)
        }
      }

    } else {
      currentEvent[field] = e.target.value;
    }
    this.setState({ currentEvent });
  }

  _deleteEventFromList() {
    this._fetchEvents(this.state.page, this.state.perPage);
  }

  async _updatePictureOnEventChange(event) {
    if (event) {
      try {
        const data = await PicturesService.getPicture(event.picture)
        this.setState({
          picture: data.picture,
          loadingEvents: false,
          currentEvent: event,
        },
          () => {
            this.forceUpdate();
          });
      } catch (err) {
        this.setState({
          picture: null,
          loadingEvents: false,
          currentEvent: event,
        },
          () => {
            this.forceUpdate();
          }
        );
      }
    }
  }

  _changeEvent(event, index = -1) {
    if (event) {
      this.setState(
        { currentEvent: event, indexEventPass: index > 0 ? index : 0 },
        () => {
          this._updatePictureOnEventChange(event);
          this._changeDateWithEvent();
          this.focusMode = true;
        }
      );
    }
  }

  _seePrevNextEvent(dir) {
    if (
      this.state.shownEvents &&
      this.state.shownEvents[this.state.indexEventPass + dir]
    ) {

      this.setState((prev) => {
        return {
          indexEventPass: prev.indexEventPass + dir,
          currentEvent: prev.shownEvents[prev.indexEventPass + dir]
        }
      },
        () => {
          this._changeDateWithEvent();
          this._updatePictureOnEventChange(this.state.currentEvent);

        });
    }
  }

  _updateAddress = address => {
    this.setState({ address: address })
  }

  _validEvents_filter = event => {
    // console.log(event)
    let cat = [...(document.querySelectorAll("#categories1 option") || [])].filter(a => a.selected)
    cat = cat.map(a => a.value)
    let finalEvent = Object.assign({}, event);
    if (cat.length)
      finalEvent.categories = cat;
    // console.log(event)
    try {
      let valid = true;

      [
        "categories",
        "description",
        "date",
        "datefin",
        "title",
        "place",
        "address",
        "coordinate"
      ].forEach(a=>{
        if(!finalEvent[a]){
          valid = false;
          this.openSnackbar(`Le champ ${a} est manquant`);
        }
      })
      if(!valid) return false
      const date = finalEvent.date;
      const datefin = finalEvent.datefin;

      const offset = new Date().getTimezoneOffset() * 60000
      let nDate = moment(date)
      if (!nDate.isValid()) {
        nDate = moment(date.date + "T" + date.time + "Z")
        if (!nDate.isValid()) {
          valid = false;
          this.openErrorSnackbar(
            "Le format de la date (" + nDate + " | " + JSON.stringify(date) + ") est invalide"
          );
        }
      }
      finalEvent.date = nDate.toISOString()

      let nDatefin = moment(datefin)
      if (!moment(datefin).isValid()) {
        nDatefin = moment(datefin.date + "T" + datefin.time + "Z")
        if (!nDatefin.isValid()) {
          valid = false;
          this.openErrorSnackbar(
            "Le format de la datefin (" + nDatefin + " | " + JSON.stringify(datefin) + ") est invalide"
          );
        }
      }
      finalEvent.datefin = nDatefin.toISOString()
      console.log(moment(finalEvent.date), moment(finalEvent.datefin))
      if (!moment(finalEvent.date).isBefore(moment(finalEvent.datefin))) {
        valid = false;
        this.openErrorSnackbar(
          "La date de début ne peut pas dépasser la date de fin"
        );
      }

      if (!event.picture)
        valid = false
      console.log(event.picture, valid)
      return valid && event
    } catch (err) {
      return false
    }
  }
  fetchingValid = false

  _validEvent = async (listOfEvent) => {
    Array.prototype.removeAll = function (a) {
      Object.assign(this, new Array(this.length).fill(undefined), typeof a == 'function' ? this.filter(a) : this.filter(b => b !== a))
      this.length = this.filter(e => !!e).length
    }
    if (this.fetchingValid) return
    this.fetchingValid = true
    console.log(listOfEvent)
    try {
      let events = (listOfEvent instanceof Array ? listOfEvent : [listOfEvent]).map(a => this._validEvents_filter(a))
      if (events.includes(false)) {
        console.log("validation of one event failed")
        return false
      }
      return await Promise.all(events.map(a =>
        this.sendEvent(a).then(() => {
          console.log(this.props.saveLocation)
          let storre = { ...this.props.saveLocation }
          if (storre.selectedEvents) storre.selectedEvents = storre.selectedEvents.filter(ev => ev !== a)
          this.props.save(storre)
        })
      )).then(() => {
        this.setState(Object.fromEntries(Object.entries(this.state).map(a => { let b = a; b[1] = ""; return b })));
        this.openSuccessSnackbar("Event validation succeeded");
      })
        .catch((err) => {
          this.openErrorSnackbar("Event validation failed");
          console.error(err);
        }).finally(() => {
          this.fetchingValid = false;
        })
    } catch (err) {
      console.log(err)
    }
  }

  sendEvent = async finalEvent => {
    return Jimp.read(
      finalEvent.picture
    )
      .then((picture) => {
        console.log("STEP II", picture)
        if (
          finalEvent.origin &&
          finalEvent.origin.toLowerCase().startsWith("evensi")
        ) {
          finalEvent.picture = picture;
          return Jimp.read("./static/images/EVENSI.png");
        } else {
          return picture
            .resize(680, 340)
            .quality(100)
            .getBase64Async(Jimp.MIME_JPEG);
        }
      })
      .then((picture) => {
        console.log("STEP III", picture)

        if (
          finalEvent.origin &&
          finalEvent.origin.toLowerCase().startsWith("evensi")
        ) {
          picture.resize(Jimp.AUTO, 20);
          const xOffset = 680 - picture.bitmap.width - 10;
          const yOffset = 340 - picture.bitmap.height - 10;
          return finalEvent.picture
            .resize(680, 340)
            .composite(picture, xOffset, yOffset)
            .quality(90)
            .getBase64Async(Jimp.MIME_JPEG);
        } else {
          return picture;
        }
      })
      .then((picture) => {
        finalEvent.picture = picture;
        console.log("réussite =================", finalEvent)
        return EventsService.validEvents(finalEvent);
      })
  }

  async _deleteEvent(eventId) {

    try {
      await EventsService.deleteMongoEvent(eventId)
      this._deleteEventFromList()

    } catch (err) {

    }
  }
  actions = {
    DELETE: this._deleteEvent,
    VALID: this._validEvent
  }

  filters = () => {
    const craw = (this.state.crawlers || []).map(a => ({ name: a, value: a }))
    return {
      crawler: [{ name: "Tous", value: 0 }, ...craw],
      categories: [{ name: "Toutes", value: 0 }, ...(this.state.categoriesList || [])],
      cities: [
        { name: "Toutes", value: { lat: 0.0, lng: 0.0 } },
        { name: "Paris", value: { lat: 48.8534, lng: 2.3488 } },
        { name: "Marseille", value: { lat: 43.296482, lng: 5.36978 } },
        { name: "Toulouse", value: { lat: 43.6043, lng: 1.4437 } },
        { name: "Nantes", value: { lat: 47.218371, lng: -1.553621 } },
        { name: "Monpelier", value: { lat: 43.6, lng: 3.8833 } },
        { name: "Strasbourg", value: { lat: 48.5734053, lng: 7.7521113 } },
        { name: "Bordeaux", value: { lat: 44.837789, lng: -0.57918 } },
        { name: "Lille", value: { lat: 50.6333, lng: 3.0667 } },
        { name: "Nice", value: { lat: 43.7031, lng: 7.2661 } },
        { name: "Lyon", value: { lat: 45.764043, lng:4.835659 } },
      ],
      distance: [
        { name: 20, value: 20 },
        { name: 50, value: 50 },
        { name: 100, value: 100 },
        { name: 150, value: 150 },
        { name: 200, value: 200 },
        { name: 500, value: 500 },
        { name: 1000, value: 1000 }
      ],
      "note Mode": [
        { name: "<=", value: 0 },
        { name: "=", value: 1 },
        { name: ">=", value: 2 }
      ],
      note: [
        { name: 6, value: 6 },
        { name: 5, value: 5 },
        { name: 4, value: 4 },
        { name: 3, value: 3 },
        { name: 2, value: 2 },
        { name: 1, value: 1 },
        { name: 0, value: 0 }
      ],
      archived: [
        { name: "NON ARCHIVE", value: 0 },
        { name: "ARCHIVE", value: 1 }
      ],
      ordre: [
        { name: "Desc", value: 0 },
        { name: "Asc", value: 1 }
      ]
    }
  }

  transformFilter = (filter, page = 1, perPage = 10) => {
    const cities = filter.cities || {}
    let cat = filter.categories && filter.categories.filter(a => a) || []
    if (!cat.length)
      cat = undefined
    return {
      searchByName: filter.searchByName || undefined,
      lat: cities.lat || 0,
      lng: cities.lng || 0,
      dist: filter.distance || 20,
      note: filter.note || 6,
      noteMode: filter["note Mode"] || 0,
      crawler: filter.crawler,
      categories: cat,
      archived: filter.archived === 2 ? undefined : !!filter.archived,
      page,
      perPage,
      order: filter.ordre
    }
  }

  render() {
    const _updateEditor = (event, editor) => {
      const data = editor.getData();
      this.setState((prevState) => ({
        currentEvent: {
          ...prevState.currentEvent,
          description: data
        }
      }));
    };



    return (
      <Page className="page" title="Events">
        <Helmet>
          <title>Crawlosaure - Events</title>
        </Helmet>
        <div className="section-title">
          <h2>Event Validation</h2>
          {this.state.loadingEvents ? null : (
            <span className="totalEventsToValidate">
              {this.state.totalEvents + " event" + (this.state.totalEvents > 1 ? "s" : "") + " to validate"}
            </span>
          )}
        </div>

        <EventList
          type={["Valid", "Delete"]}
          filters={this.filters()}
          EventSource={async (filters, page, perPage) => await AuthService.check().then(() => EventsService.getFilteredCrawledEvents(
            this.transformFilter(filters, page, perPage)
          ))}
          saver={this.props.save}
          getSave={() => this.props.saveLocation}
          open={e => { this.focusMode = true; this.setState({ currentEvent: e }) }}
          actions={this.actions}
          row={EventRow}
        />
        {this.state.currentEvent && this.focusMode &&
          <EventFocusView
            type={"Valid Event"}
            _updateEditor={_updateEditor}
            _updateField={this._updateField}
            _updateAddress={this._updateAddress}
            loadingEvents={this.state.loadingEvents}
            event={this.state.currentEvent}
            onClose={() => { this.focusMode = false; this.forceUpdate(); }}
            _seePrevNextEvent={(i) => this._seePrevNextEvent(i)}
            _deleteEvent={(id) => this._seePrevNextEvent(id)}
            _validEvent={this._validEvent}
            categoriesList={this.state.categoriesList}
            updateEvent={this.setState}
          />}

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={() => this.openSnackbar("", false)}
          message={this.state.snackbarMessage}
          severity="error"
        >
          <Alert onClose={() => this.openSnackbar("", false)} severity="error">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </Page>
    );
  }
}

export default connect(a => ({ saveLocation: a.eventData.eventLists.mongo }), { save: Save.setEventsMongo })(EventValidation)

const EventRow = (props) => {
  const rstore = store.getState();
  // const [value, setValue] = React.useState(0);

  EventRow.defaultProps = {
    select: console.log,
    event: {},
  }

  EventRow.propTypes = {
    checked: PropTypes.bool,
    info: PropTypes.string,
    event: PropTypes.object.isRequired,
    select: PropTypes.func,
  }

  const handleCheckbox = e => {
    e.stopPropagation();
    return props.select(props.event)
  }

  const DefaultPicture = 'https://i.ibb.co/Yh2NX6Z/Time-N-Joy.png'
  let open = useRef()
  // console.log(props.event)
  return (
    <ListItem button className="eventRow" onClick={() => props.open && props.open(props.event)}>
      <ListItemIcon>
        <Checkbox checked={props.checked}
          onClick={handleCheckbox} />
      </ListItemIcon>
      <ListItemAvatar className="image">
        <img src={props.event.picture} alt="event poster" onError={e => (e.target.src = DefaultPicture)} />
      </ListItemAvatar>

      <ListItemText className="text title" primary={
        <>
          {props.event.title.substring(0, 30)}
          <br />
          {props.event.categories.map(x => x.name).join(", ")}
          <br />
          {props.event.origin}          
        </>
      } />

      <ListItemText primary={props.event.date ? moment.utc(props.event.date).format('lll') : "NO DATE"} secondary={props.event.datefin ? moment.utc(props.event.datefin).format('lll') : "NO DATE"} />

      {props.event.note !== undefined && (<ListItemText primary={props.event.note + '/6'} />)}

      <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>

        <Rating value={props.event.inevitableRate || 0} onChange={(e, newValue) => {
          props.event.inevitable = !!newValue
          props.event.inevitableRate = newValue
          props.updateEvent(props.event)
        }} />

        <IconButton edge="end" onClick={(e) => { open = e.currentTarget; console.log(Boolean(open), open); }} ref={open}>
          <InfoIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>)
}
