import React, { useState, useRef } from "react";
import Link from '@material-ui/core/Link';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
  } from '@material-ui/core';
import TopBar from '../../components/TopBar'
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import { logIn } from '../../helpers/utils'
import UsersService from '../../services/UsersService';
import { useDispatch, useSelector } from "react-redux";
import { getApiToken }from '../../store/apiToken/actions'
import { Helmet, HelmetProvider } from "react-helmet-async";
import AuthService from "../../services/AuthService";
import './login.scss'
const Login = (props) => {
  const activeToken = useSelector(state => state.apiToken.activeApiToken);
  const dispatch = useDispatch();

  const form = useRef();
  const checkBtn = useRef();
  const [loading, setLoading] = useState(false);

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  const onChangeMail = (e) => {
    const mail = e.target.value;
    setMail(mail);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (evt) => {
    evt.preventDefault();
    setLoading(true);
  
    const data = {
        mail: mail,
        password: password
    }

    // Result of login is an object with informations about user.
    // Can be useful in futur.
    UsersService.login(data)
    .then((res) => {
        if (!activeToken) {
          setLoading(false);
          return dispatch(getApiToken(AuthService.getToken()))
        } else { 
          setLoading(false);
          return true 
        }
    })
    .then(() => {
      logIn(props.history);
    })
    .catch(error => {
      setLoading(false);
    })
  }

  return (
    <div className="login-view">
      <Helmet>
            <title>Login</title>
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
        <TopBar />
            <Box mb={3}>
              <Typography
                color="textPrimary"
                align="center"
                variant="h2"
              >
                Connectez-vous
              </Typography>
            </Box>
            <Form onSubmit={handleLogin} ref={form}>
                <TextField
                    fullWidth
                    label="Email Address"
                    margin="normal"
                    name="email"
                    value={mail}
                    onChange={onChangeMail}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    type='password'
                    label="Password"
                    margin="normal"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    variant="outlined"
                />

              <Box my={2}>
                <Button 
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </Button>
              </Box>

              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://timenjoy.fr/">
                Timenjoy
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
        </Container>
      </Box>
    </div>
  );
}

export default Login;