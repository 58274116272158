import { initialState } from "./states";

export const eventData = (state = initialState, action) => {
  switch (action.type) {
    // case "UNSET_SELECTED_EVENTS_MONGO": {
    //   return {
    //     ...state,
    //     eventsMongo : state.eventsMongo.filter(e=>e.id !== action.data.id)
    //   }
    // }
    // case "UNSET_SELECTED_EVENTS_SAILS": {
    //   return {
    //     ...state,
    //     eventsSails : state.eventsSails.filter(e=>e.id !== action.data.id)
    //   }
    // }
    case "SET_SELECTED_EVENTS_MONGO": {
      return {
        ...state,
        eventLists : {...state.eventLists, mongo : action.data}
      }
    }
    case "SET_SELECTED_EVENTS_SAILS": {
      return {
        ...state,
        eventLists : {...state.eventLists, sails : action.data}
      }
    }
    default:
      return state;
  }
};
