import { initialState } from "./states";

export const userData = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_USER": {
      return Object.assign({}, state, 
        action.data.user,
        {company: action.data.company},
        {token: action.data.token}
      );
      
    }
    case "RESET_USER_DATA": {
      return initialState;
    }
    default:
      return state;
  }
};
