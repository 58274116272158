import { mutation } from "./mutations";

const handleError = err => {
  console.error("Error in apiToken action : ", err);
};

export const setEventsMongo = data => dispatch => {
  try {
    dispatch(mutation.setEventsMongo(data));
    console.log("reussite");
  } catch (err) {
    console.log("erreur");
    handleError(err);
  }
};

export const setEventsSails = data => dispatch => {
  try {
    dispatch(mutation.setEventsSails(data));
    console.log("réussite");
  } catch (err) {
    console.log("erreur");
    handleError(err);
  }
};