import React, { Component } from "react";
import { connect } from "react-redux";
import Page from "../../components/Page/Page";
import { Button, Card, Grid, TextField } from "@material-ui/core";
import './Profile.scss';
import { Helmet } from 'react-helmet-async';


class Profile extends Component {

    render() {
        return (
            <>
                <Page className="profile" title="Profile">
                    <Helmet>
                        <title>Crawlosaure - Profile</title>
                    </Helmet>
                    
                    <div className="section-title">
                        <h2>Profile</h2>
                    </div>
                    <Grid container direction="column" alignItems="center" xs={12}>
                        <Grid container direction="row" alignItems="center" spacing={4} xs={12}>
                            <Grid item xs={6}>
                                <label>Nom : </label>
                                <input
                                    className="input_f"
                                    id="profile-lname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={this.props.lastname}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <label>Prénom : </label>
                                <input
                                    className="input_f"
                                    id="profile-fname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={this.props.firstname}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" spacing={4} xs={12}>
                            <Grid item xs={6} style={{ marginTop: 100 }}>
                                <label>Adresse mail : </label>
                                <input
                                    className="input_f"
                                    id="profile-lname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={this.props.email}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: 100 }}>
                                <label>Téléphone: </label>
                                <input
                                    className="input_f"
                                    id="profile-lname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={this.props.phone}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" spacing={4} xs={12}>
                            <Grid item xs={6} style={{ marginTop: 100 }}>
                                <label>Ville: </label>
                                <input
                                    className="input_f"
                                    id="profile-lname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={this.props.city}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: 100 }}>
                                <label>Adresse: </label>
                                <input
                                    className="input_f"
                                    id="profile-fname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={this.props.address}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" spacing={4} xs={12}>
                            <Grid item xs={6} style={{ marginTop: 100 }}>
                                <label>Code postal: </label>
                                <input
                                    className="input_f"
                                    id="profile-lname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={this.props.zip_code}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: 100 }}>
                                <label>Rôle: </label>
                                <input
                                    className="input_f"
                                    id="profile-fname-input"
                                    type="text"
                                    onChange={() => { }}
                                    value={(this.props.roles || "").slice(-8, -3)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Page>
            </>)
    }
}

export default connect(a => (a.userData || a))(Profile)