import axios from "axios";
import { APISAILS_URL } from "./apiUrl";
import store from '../../store';
import moment from 'moment';

const _authorizationHeaders = () => {
  const state = store.getState();
  return ({
    Authorization: (state.apiToken && state.apiToken.activeApiToken) ? "Bearer " + state.apiToken.activeApiToken.token : "",
    "Content-Type": "application/json"
  })
};

const handleError = err => {
  console.error(`Api call error in services -> request.js : `, err);
};

const formatEvent = event => {
  return {
    id: event.id,
    // date: moment.utc(new Date(event.date)).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    // datefin: moment.utc(new Date(event.datefin)).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    date: moment(new Date(event.date)).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    datefin: moment(new Date(event.datefin)).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    place: event.place,
    title: event.title,
    tags: (Array.isArray(event.tags) ? event.tags : []).map(e => e.name),
    picture: `${APISAILS_URL}/event/${event.id}/getImage`,
    description: event.description,
    pictureId: Array.isArray(event.pictures) ? (event.pictures[0] || {}).id || 0 : 0,
    site: event.site,
    url_ticketing: event.url_ticketing,
    // url_game:event.url_game,
    published: event.published,
    // price_order_id:event.price_order_id,
    chatbot: event.chatbot,
    inevitable: event.inevitable,
    inevitableRate: event.inevitableRate,
    origin: event.origin,
    company: (event.company_events[0] || {}).company_id,
    address: (event.coordinate || {}).address,
    coordinateId: (event.coordinate || {}).id,
    coordinate: {
      id: (event.coordinate || {}).id,
      lat: (event.coordinate || {}).lat,
      lng: (event.coordinate || {}).lng
    },
    categories: Array.isArray(event.categories) ? event.categories.map(e => ({ id: e.id, name: e.name })) : undefined
  }
}

export const get = (url, params = null, headers = _authorizationHeaders()) => {
  return axios.get(APISAILS_URL + url, {
    headers: Object.assign({}, headers),
    params: Object.assign({}, params),
  })
    .then(res => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch(err => {
      throw err;
    })
};

export const post = (url, data = {}, headers = _authorizationHeaders()) => {
  return axios({
    url: APISAILS_URL + url,
    method: "POST",
    headers: Object.assign({}, headers),
    data
  })
    .then(res => {
      return res.data.result ? res.data.result : res.data;

    })
    .catch(err => {
      throw err;
    })
};

export const put = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: APISAILS_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const patch = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: APISAILS_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const deleted = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: APISAILS_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const ApiSails = {
  get,
  post,
  put,
  deleted,
  patch,
  formatEvent
};
