import EventB from './EventList'

// import React, { useState } from "react";
// import { CRAWLOSAURE_API, APISAILS_URL } from "../../services/config/apiUrl";
// // import { v4 as uuidv4 } from 'uuid';
// import moment from 'moment';
// import 'moment/locale/fr'

// import Pagination from '@material-ui/lab/Pagination';
// import MuiAlert from "@material-ui/lab/Alert/Alert";
// import InfoIcon from '@material-ui/icons/Info';
// import { connect } from 'react-redux';
// // import Typography from '@material-ui/core/Typography';
// import './eventList.scss'
// import {
//     Button,
//     Card,
//     Checkbox,
//     Divider,
//     FormControl,
//     makeStyles,
//     List,
//     ListSubheader,
//     ListItem,
//     ListItemIcon,
//     ListItemText,
//     ListItemSecondaryAction,
//     IconButton,
//     Snackbar,
//     InputLabel,
//     MenuItem,
//     Select,
//     ListItemAvatar,
// } from "@material-ui/core";

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

// const useStyle = makeStyles(theme => ({
//     card: {
//         paddingTop: 10,
//         margin: 5,
//     },
//     header: {
//         display: 'flex',
//         // justifyContent: 'space-between',
//         justifyContent: 'space-around',
//         fontSize: '1.2em',
//         paddingTop: '-10px',
//         color: theme.palette.text.primary,
//     },
//     pagination: {
//         width: '500px',
//         margin: '0 auto',
//         padding: '1em 0',
//     },
//     button: {
//         backgroundColor: theme.palette.background.dark,
//     },
//     divider: {
//         width: '60%',
//         margin: '0 auto',
//     }
// }))

// function EventList(props) {
//     const classes = useStyle();
//     const [snackOpen, setSnackOpen] = useState(false);
//     const [snackMessage, setSnackMessage] = useState('');
//     const [cityName, setCityName] = useState('TOUTES');

//     const handleChange = (t, page) => {
//         props.changePage(page);
//     };

//     const openSnackbar = (msg = '', open = true) => {
//         setSnackMessage(msg)
//         setSnackOpen(open)
//     }

//     const handleClick = (event_id) => {
//         props.selectEvent(event_id)
//     }

//     const getUrl = (event) => {

//       if (event.picture) {
//         return `${CRAWLOSAURE_API}/pictures/${event.picture}/getUrl`
//       }
//       else {
//         return `${APISAILS_URL}/event/${event.id}/getImage`
//       }
//     }

//     return (
//         <div>
//             <Card className={classes.card}>
//                 <div style={{ display: 'flex', justifyContent: "space-evenly", marginBottom: '20px' }}>
//                     {props.crawler !== false && (<FormControl style={{ width: '10em' }}>
//                         <InputLabel id="demo-simple-select-label">Crawler</InputLabel>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={props.crawlerValue}
//                             onChange={(e) => {
//                                 props.changeFilter('CRAWLER', e);
//                             }}
//                             defaultValue={'TOUS'}
//                         >
//                             <MenuItem value={'TOUS'}> Tous </MenuItem>
//                             {
//                                 props.crawlers.map((name, i) =>
//                                     <MenuItem key={i} value={name}> {name} </MenuItem>
//                                 )
//                             }
//                         </Select>
//                     </FormControl>)}
//                     <FormControl style={{ width: '15em' }}>
//                         {/* <InputLabel shrink id="demo-simple-select-placeholder-label-label">Category</InputLabel> */}
//                         <InputLabel shrink id="demo-simple-select-label">Category</InputLabel>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="categories"
//                             displayEmpty
//                             renderValue={value => props.categoryValue ? props.categoryValue.children : "Toutes"}
//                             value={props.categoryValue ? props.categoryValue.value : ''}
//                             // defaultValue={'Toutes'}
//                             onChange={e => props.changeFilter('CATEGORIES', e)}
//                         >
//                             <MenuItem value={{ value: '', children: "Toutes" }} /*disabled*/>Toutes</MenuItem>
//                             {props.categoriesList && props.categoriesList.map((c, i) =>
//                                 <MenuItem key={i} value={c.props.value}> {c.key} </MenuItem>)}
//                         </Select>
//                     </FormControl>
//                     <FormControl style={{ width: '10em' }} >
//                         <InputLabel id="demo-simple-select-label">Ville</InputLabel>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={props.cityName}
//                             onChange={(e) => {
//                                 props.changeFilter('CITY', e);
//                                 setCityName(e.target.value);
//                             }}
//                             defaultValue={'TOUTES'}
//                         >
//                             <MenuItem value={'TOUTES'}> Toutes </MenuItem>
//                             <MenuItem value={'PARIS'}>Paris</MenuItem>
//                             <MenuItem value={'MARSEILLE'}>Marseille</MenuItem>
//                             <MenuItem value={'TOULOUSE'}>Toulouse</MenuItem>
//                             <MenuItem value={'NICE'}>Nice</MenuItem>
//                             <MenuItem value={'NANTES'}>Nantes</MenuItem>
//                             <MenuItem value={'MONTPELIER'}>Montpellier</MenuItem>
//                             <MenuItem value={'STRASBOURG'}>Strasbourg</MenuItem>
//                             <MenuItem value={'BORDEAUX'}>Bordeaux</MenuItem>
//                             <MenuItem value={'LILLE'}>Lille</MenuItem>
//                         </Select>
//                     </FormControl>
//                     <FormControl >
//                         <InputLabel id="demo-simple-select-label">Distance</InputLabel>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={props.distanceValue}
//                             onChange={(e) => { props.changeFilter('DISTANCE', e) }}
//                             defaultValue={50}
//                         >
//                             <MenuItem value={20}>20</MenuItem>
//                             <MenuItem value={50}>50</MenuItem>
//                             <MenuItem value={100}>100</MenuItem>
//                             <MenuItem value={150}>150</MenuItem>
//                             <MenuItem value={200}>200</MenuItem>
//                             <MenuItem value={500}>500</MenuItem>
//                             <MenuItem value={1000}>1000</MenuItem>
//                         </Select>
//                     </FormControl>
//                     {props.note !== false && (<>
//                         <FormControl >
//                             <InputLabel id="demo-simple-select-label">Note Mode</InputLabel>
//                             <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 value={props.noteModeValue}
//                                 onChange={(e) => {
//                                     props.changeFilter('NOTEMODE', e);
//                                 }}
//                                 defaultValue={0}
//                             >
//                                 <MenuItem value={0}> {'<='} </MenuItem>
//                                 <MenuItem value={1}> {'='} </MenuItem>
//                                 <MenuItem value={2}> {'>='} </MenuItem>
//                             </Select>
//                         </FormControl>
//                         <FormControl >
//                             <InputLabel id="demo-simple-select-label">Note</InputLabel>
//                             <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 value={props.noteValue}
//                                 onChange={(e) => {
//                                     props.changeFilter('NOTE', e);
//                                 }}
//                                 defaultValue={6}
//                             >
//                                 <MenuItem value={0}>0</MenuItem>
//                                 <MenuItem value={1}>1</MenuItem>
//                                 <MenuItem value={2}>2</MenuItem>
//                                 <MenuItem value={3}>3</MenuItem>
//                                 <MenuItem value={4}>4</MenuItem>
//                                 <MenuItem value={5}>5</MenuItem>
//                                 <MenuItem value={6}>6</MenuItem>
//                             </Select>
//                         </FormControl>
//                     </>)}
//                     {props.old && (
//                         <FormControl >
//                             <InputLabel id="demo-simple-select-label">ANCIENNETÉ</InputLabel>
//                             <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 value={props.oldValue}
//                                 onChange={(e) => {
//                                     props.changeFilter('OLD', e);
//                                 }}
//                                 defaultValue={2}
//                             >
//                                 <MenuItem value={2}>TOUT</MenuItem>
//                                 <MenuItem value={1}>TERMINÉS</MenuItem>
//                                 <MenuItem value={0}>EN COURS</MenuItem>
//                             </Select>
//                         </FormControl>
//                     )}
//                     <FormControl >
//                             <InputLabel id="demo-simple-select-label">ORDER</InputLabel>
//                             <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 value={props.orderValue}
//                                 onChange={(e) => {
//                                     props.changeFilter('ORDER', e);
//                                 }}
//                                 defaultValue={0}
//                             >
//                                 <MenuItem value={1}>ASC</MenuItem>
//                                 <MenuItem value={0}>DESC</MenuItem>
//                             </Select>
//                         </FormControl>
//                     <Button
//                         className={classes.button}
//                         onClick={() => {
//                             setCityName({ cityName: 'TOUTES' });
//                             props.reinitFilters();
//                         }}>Remove Filters</Button>
//                 </div>
//             </Card>

//             <Card className={classes.card}>
//                 <List
//                     component="ul"
//                     aria-labelledby="nested-list-subheader"
//                     subheader={
//                         <ListSubheader component="div" className={classes.header}>
//                             <h6>{props.count || "Crawled"} events</h6>
//                             <div>
//                                 <Button>Delete</Button>
//                                 <Button>Test</Button>
//                             </div>
//                         </ListSubheader>
//                     }
//                     className={"collection-item folder"}
//                 >
//                     {(props.events && props.events.length > 0) ?
//                         (props.events.map((event, index) => {
//                             let note = 0;
//                             let problems = [];
//                             // note = 0;
//                             if ((event.coordinate.lat && event.coordinate.lat.length) && (event.coordinate.lng && event.coordinate.lng.length)) {
//                                 note++;
//                             } else { problems.push('Lat/Lon'); }
//                             if ((event && event.datefin) && (event && event.date)) {
//                                 if (event.datefin.date && event.date.date) {
//                                     note++;
//                                 } else if (moment(event.date).isValid() && moment(event.datefin).isValid()) {
//                                     note++;
//                                 } else {
//                                     problems.push('Dates');
//                                 }
//                             } else { problems.push('Dates'); }
//                             if (event.categories && event.categories.length > 0) {
//                                 note++;
//                             } else { problems.push('Categories'); }
//                             if (event.description && event.description !== "") {
//                                 note++;
//                             } else { problems.push('Description'); }
//                             if (event.place && event.place !== "") {
//                                 note++;
//                             } else { problems.push('Place'); }
//                             if (event.address && event.address !== "") {
//                                 note++;
//                             } else { problems.push('Address'); }
//                             return (
//                                 <ListItem key={JSON.stringify(event)} button onClick={() => props.oneventclick(event, index)}>
//                                     <ListItemIcon>
//                                         <Checkbox checked={event.checked || false}
//                                             onClick={e => {e.stopPropagation(); return handleClick(event)}} />
//                                     </ListItemIcon>
//                                     <ListItemAvatar style={{ maxWidth: '150px', background: '#F5F5F5', justifyContent: 'center', margin: "0 56px" }}>
//                                         <img width="100px" src={getUrl(event)} alt="event poster" onError={e=>(e.target.src='https://i.ibb.co/znCWYwJ/Time-N-Joy.png')}/> {/*src='https://www.brownweinraub.com/wp-content/uploads/2017/09/placeholder.jpg' */}
//                                     </ListItemAvatar>
//                                     <ListItemText primary={event.title.slice(0, 42)} style={{ maxWidth: '400px' }} />
//                                     <ListItemText primary={event.date ? (event.date.date ?
//                                         moment(event.date.date).hours(event.date.time.split(':')[0]).minutes(event.date.time.split(':')[1]).format('lll') :
//                                         moment(event.date).format('lll')) : "undefined"}
//                                         style={{ maxWidth: '200px' }}
//                                     />
//                                     <ListItemText primary={event.datefin ? (event.datefin.date ?
//                                         moment(event.datefin.date).hours(event.datefin.time.split(':')[0]).minutes(event.datefin.time.split(':')[1]).format('lll') :
//                                         moment(event.datefin).format('lll')) : "undefined"}
//                                         style={{ maxWidth: '200px' }}
//                                     />
//                                     {props.note !== false && (<ListItemText primary={note + '/6'} style={{ maxWidth: '40px' }} />)}
//                                     <ListItemSecondaryAction>

//                                         <IconButton edge="end" aria-label="delete" onClick={() => {
//                                             openSnackbar('Champ vide : ' + problems.toString().toUpperCase())
//                                         }}>
//                                             <InfoIcon />
//                                         </IconButton>
//                                     </ListItemSecondaryAction>
//                                 </ListItem>
//                             )
//                         })) : (
//                             <ListItem className="collection-item folder">
//                                 <ListItemText primary={'Aucun event, lancez un crawler !'} />
//                             </ListItem>
//                         )
//                     }
//                 </List>
//             </Card>
//             <div className={classes.pagination}>
//                 {(props.events && props.events.length > 0) ?
//                     (<Pagination
//                         count={Math.round(props.totalEvents / props.number)}
//                         page={props.page - 1}
//                         onChange={handleChange}
//                         showFirstButton showLastButton />) :
//                     ("")
//                 }
//             </div>

//             <Divider className={classes.divider} />

//             <Snackbar
//                 anchorOrigin={{
//                     vertical: 'bottom',
//                     horizontal: 'center',
//                 }}
//                 open={snackOpen}
//                 autoHideDuration={6000}
//                 onClose={() => openSnackbar('', false)}
//                 message={snackMessage}
//                 severity="error"
//                 variant="filled"
//             >
//                 <Alert onClose={() => openSnackbar('', false)} severity="info">
//                     {snackMessage}
//                 </Alert>
//             </Snackbar>
//         </div>
//     )
// }

// export default connect(a => a.eventData)(EventList);
export default EventB;
