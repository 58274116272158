import { initialState } from "./states";

export const errorMessage = (state = initialState, action) => {
  switch (action.type) {
    // case "SET_ERRORMESSAGE_LIST": {
    //   return Object.assign({}, state, {
    //     errorMessageList: action.data
    //   });
    // }

    case "SET_ACTIVE_ERRORMESSAGE": {
      return Object.assign({}, state, {
        activeErrorMessage: action.data
      });
    }
    case "CLEAR_ERRORMESSAGE": {
      return Object.assign({}, state, {
        activeErrorMessage: null
      });
    }
    // case "SET_ERRORMESSAGE_LIST_DATA": {
    //   const data = [...state.errorMessageList];
    //   data.unshift(action.data);
    //   return Object.assign({}, state, {
    //     errorMessageList: data
    //   });
    // }
    // case "REMOVE_ERRORMESSAGE_FROM_LIST": {
    //   const data = state.errorMessageList.filter(data => data.id !== action.data);
    //   return Object.assign({}, state, {
    //     errorMessageList: data
    //   });
    // }
    // case "SET_UPDATE_ERRORMESSAGE_LIST_DATA": {
    //   const data = state.errorMessageList.map(data => {
    //     if (data.id === action.data.id) {
    //       return (data = action.data);
    //     } else {
    //       return data;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     errorMessageList: data
    //   });
    // }
    // case "IS_FETCHING_DATA": {
    //   return Object.assign({}, state, {
    //     isFetching: action.data
    //   });
    // }
    default:
      return state;
  }
};
