import React, { useEffect } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
  RefreshCw as RefreshIcon,
  ChevronDown as DropIcon,
} from 'react-feather';
import { indigo } from "@material-ui/core/colors";

import enhancedReducer from '../../helpers/enhancedReducer'
import TopBar from '../../components/TopBar'
import Page from '../../components/Page/Page'
import NavBar from '../../components/NavBar/index'
import Loader from '../../components/Loader/Loader'

import CrawlersService from '../../services/CrawlersService';
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Container,
  IconButton,
  Typography,
  Snackbar,
  Card,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(2),
  },
  title: {
    display: 'flex',
    marginLeft: -25,
    fontFamily: 'Roboto',
    '& h1': {
      fontWeight: 300,
    }
  },
  loadingCrawler: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  icons: {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
    paddingLeft: 12,
  },
  subtitle: {
    padding: '0px 25px',
  },
  subIcon: {
    marginTop: 5,
    marginRight: 10,
    verticalAlign: 'bottom'
  }
}));

const initialState = {
  crawlerCategory: '',
  crawlers: [],
  crawledEvents: [],
  loadingEvents: true,
  events: [],
  coordinatesFilter: null,
  areaFilter: 50,
  shownEvents: [],
  picture: null,
  pictureCropped: null,
  showCrawlers: true,
  showFilters: true,
  editorState: null,

  snackbarMessage: '',
  snackbarOpen: false,
}

export default function Crawlers() {
  const classes = useStyles();
  const [state, updateState] = React.useReducer(enhancedReducer, initialState);

  useEffect(() => {
    CrawlersService
      .getCrawlers()
      .then(crawlers => {
        updateState({ crawlers })
      });
  }, [])

  const openSnackbar = (msg = '', open = true) => {
    updateState({ snackbarMessage: msg, snackbarOpen: open })
  }

  const executeCrawler = (crawlerName, crawlerCategory) => {
    let crawlers = state.crawlers;
    updateState({ loadingCrawler: true });
    CrawlersService
      .executeCrawler(crawlerName, crawlerCategory)
      .then(() => {
        crawlers.find(c => c.name === crawlerName).lastExecution = moment().toISOString();
        updateState({ crawlers, loadingCrawler: false });
        openSnackbar();
        // window.location.reload();
      })
      .catch(err => {
        updateState({ crawlers, loadingCrawler: false });
      })
  }

  const toggleCrawlerCategory = (category) => {
    if (state.crawlerCategory === category) {
      return updateState({ crawlerCategory: '' })
    }
    updateState({ crawlerCategory: category });
  }

  const toggleCrawlerVisibility = () => {
    updateState({ showCrawlers: !state.showCrawlers })
  }

  // Display crawler name with space
  const cleanRender = (name) => {
    return name.split('.js')[0].replace(/_/g, ' ')
  }

  let selectedEvent = state.selectedEvent;
  let dateDateProps = {};
  let dateTimeProps = {};
  let dateFinDateProps = {};
  let dateFinTimeProps = {};
  if (selectedEvent && selectedEvent.date && !selectedEvent.date.date) {
    dateDateProps = { defaultValue: moment(selectedEvent.date).format('YYYY-MM-DD') };
    dateTimeProps = { defaultValue: moment(selectedEvent.date).format('HH:mm') };
    selectedEvent.date = {
      date: dateDateProps.defaultValue,
      time: dateTimeProps.defaultValue
    }
  }
  if (selectedEvent && selectedEvent.datefin && !selectedEvent.datefin.date) {
    dateFinDateProps = { defaultValue: moment(selectedEvent.datefin).format('YYYY-MM-DD') };
    dateFinTimeProps = { defaultValue: moment(selectedEvent.datefin).format('HH:mm') };
    selectedEvent.datefin = {
      date: dateFinDateProps.defaultValue,
      time: dateFinTimeProps.defaultValue
    }
  }

  return (
    <Page
      className={classes.root}
      title="Crawlers"
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={state.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => openSnackbar('', false)}
        message="Events crawled !"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => openSnackbar('', false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Helmet>
        <title>Crawlosaure - Crawlers</title>
      </Helmet>
      <Box mb={3}>
        <Container className={classes.title}>
          <h2>Crawlers</h2>
          <Typography className={classes.icons}
            onClick={toggleCrawlerVisibility}
            variant="body1">
            {state.showCrawlers ? <><EyeIcon size='15' /> Hide</>
              : <><EyeOffIcon size='15' /> Show</>}
          </Typography>
        </Container>
      </Box>
      {state.showCrawlers && (
        <Card key={uuidv4()} style={{ paddingTop: 20, marginLeft: 10 }}>
          <PerfectScrollbar>
            <ul className="collection">
              {state.loadingCrawler && (
                <li className={classes.loadingCrawler}>
                  <Loader />
                </li>
              )}
              {
                state.crawlers.map(crawler => crawler.category)
                  .filter((val, i, self) => self.indexOf(val) === i)
                  .map(category => {
                    return (
                      <Box mb={3} minWidth={1050} key={uuidv4()}>
                        <Typography style={{ cursor: 'pointer' }}
                          className={classes.subtitle}
                          onClick={() => toggleCrawlerCategory(category)}
                          variant="button">
                          <DropIcon className={classes.subIcon} size='20' />
                          {category}
                        </Typography>
                        <ul>
                          {category === state.crawlerCategory && (
                            state.crawlers.filter(c => c.category === category).map((crawler, index) => {
                              return (
                                <li className={classes.subtitle} key={uuidv4()}>
                                  <RefreshIcon
                                    style={{ cursor: 'pointer', margin: '-2px 10px -2px 30px', color: indigo[500] }}
                                    size='13'
                                    onClick={() => executeCrawler(crawler.name, category)} />
                                  <Typography variant='overline'>
                                    {cleanRender(crawler.name)}
                                  </Typography>
                                  <Typography variant='caption'>
                                    {crawler.lastExecution ?
                                      " - Executed " + moment(crawler.lastExecution).fromNow()
                                      : " - Never executed"}
                                  </Typography>
                                </li>
                              )
                            })
                          )}
                        </ul>
                      </Box>
                    )
                  })
              }
            </ul>
          </PerfectScrollbar>
        </Card>
      )}
    </Page>
  )
}
