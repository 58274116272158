import axios from "axios";
import { CRAWLOSAURE_API } from "./apiUrl";
import CategoriesService from "../../services/CategoriesService";

const handleError = err => {
  console.error(`Api call error in services -> request.js : `, err);
};
let categoriesList;
const formatEvent = async event => {

  categoriesList = categoriesList && categoriesList.length ? categoriesList : await CategoriesService.getCategories()
    .then(res => res.rows)
    .catch(() => [])

  const cat = (event.categories || []).map(category => ({
    id: category,
    name: (categoriesList.find(x =>{
      return x.id == category}
      ) || {}).name
  }))

  return {
    id: event._id,
    date: event.date,
    datefin: event.datefin,
    place: event.place,
    title: event.title,
    tags: event.tags || [],
    picture: `${CRAWLOSAURE_API}/pictures/${event.picture}/getUrl`,
    description: event.description,
    site: event.url,
    url_ticketing: event.url_ticketing,
    origin: event.origin,
    address: event.address,
    coordinate: {
      lat: event.lat,
      lng: event.lng
    },
    categories: cat,
    archived: event.archived,
    occurences: event.occurences
  }
}


export const get = (url, params = null) => {
  return axios.get(CRAWLOSAURE_API + url, {
    params: Object.assign({}, params),
  })
    .then(res => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch(err => {
      throw err;
    })
};

export const post = (url, data = {}) => {
  return axios({
    url: CRAWLOSAURE_API + url,
    method: "POST",
    data
  })
    .then(res => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch(err => {
      throw err;
    })
};

export const put = async (
  url,
  data = {}
) => {
  try {
    const res = await axios({
      url: CRAWLOSAURE_API + url,
      method: "PUT",
      data
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const patch = async (
  url,
  data = {},
) => {
  try {
    const res = await axios({
      url: CRAWLOSAURE_API + url,
      method: "PATCH",
      data
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const remove = async (
  url,
  data = {},
) => {
  try {
    const res = await axios({
      url: CRAWLOSAURE_API + url,
      method: "DELETE",
      data
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const ApiMongo = {
  get,
  post,
  put,
  patch,
  remove,
  formatEvent
};
