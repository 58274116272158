import React, { Component } from "react";
import './eventFocusView.scss'
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Card, Grid, Checkbox } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Select } from "react-materialize";
import { ExternalLink } from "react-feather";

import { clearLocal } from '../../helpers/utils';
import Tags from '../Tags';
import Map from "../Map";
import PictureLoader from '../PictureLoader'

export default class EventValidation extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, props);
    this.setState = this.setState.bind(this);
    this.fetchedAddress = this.props.event.address
  }

  render() {

    let state = Object.assign({}, this.state, this.props);

    const handleAddressChange = value => {
      console.log("value", value)
      if (value.lat) {
        this.fetchedAddress = value.address
      }
      this.setState(a => {
        let currentState = { ...a };
        currentState.event.address = value.address;
        currentState.event.coordinate = { lat: (value.lat || currentState.event.coordinate.lat).toString(), lng: (value.lng || currentState.event.coordinate.lng).toString() }
        return a
      })
    }
    const date = state.event.date.date ?
      { date: state.event.date.date, time: state.event.date.time } :
      { date: state.event.date.slice(0, 10), time: state.event.date.slice(11, -7) }
    const dateFin = state.event.datefin.date ?
      { date: state.event.datefin.date, time: state.event.datefin.time } :
      { date: state.event.datefin.slice(0, 10), time: state.event.datefin.slice(11, -7) }

    return (
      <div className="event-focus-view" onClick={state.onClose} >
        {clearLocal()}
        <Card className="card" onClick={e => e.stopPropagation()}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={6} className="top-title">
              <h2>{this.props.type}</h2>
            </Grid>
            <Grid item xs={6} className="top-close">
              <Button
                variant="contained"
                style={{ backgroundColor: "light-grey" }}
                onClick={state.onClose}
              > Close </Button>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} className="light-info">
              <div>
                <label>
                  <b>Extract of :</b>
                </label>
                <input
                  type="text"
                  id="text"
                  value={state.event.origin || "No origin"}
                  onChange={state._updateField("origin")}
                />
              </div>
              <div>
                <label>

                  Url site : { state.event.site ? <a href={state.event.site} target="_blank">See</a> : ''}

                </label>
                <input
                  type="url"
                  id="url"
                  value={state.event.site /*|| state.event.url_ticketing*/ || "No url"}
                  onSubmit={e => window.open(e.target.value || "#")}
                  onChange={state._updateField("site")}
                />
              </div>
              <div>
                <label>

                  Url ticketing : { state.event.url_ticketing ? <a href={state.event.url_ticketing} target="_blank">See</a> : ''}

                </label>
                <input
                  type="url"
                  id="ticketing_url"
                  value={state.event.url_ticketing /*|| state.event.site*/ || "Not available"}
                  onSubmit={e => window.open(e.target.value)}
                  onChange={state._updateField("url_ticketing")}
                />
              </div>
            </Grid>

            <Grid container justify="flex-start" alignItems="center">
              <Grid item xs={6} className="picture">
                <PictureLoader defaultPicture={state.event.picture} onChange={a => state._updateField("picture")({ target: { value: a } })} />
              </Grid>
              <Grid item xs={6} className="first">
                <label>Title : </label>
                <input
                  className="input_f"
                  id="title"
                  type="text"
                  // onChange={() => state._updateField("title")} // FAIL
                  // onChange={(e) => state._updateField("title",e)} // OK avec le bind au début
                  onChange={state._updateField("title")} // OK methode fléchée
                  value={state.event.title}
                />

                <label>Place : </label>
                <input
                  className="input_f"
                  type="text"
                  onChange={state._updateField("place")}
                  value={
                    state.event.place
                      ? state.event.place
                      : ""
                  } />
                <div className="field">
                  <label>Start : </label>
                  <input
                    className="date"
                    id="date-date"
                    // placeholder="2017-07-05T09:51:03.519Z"
                    value={date.date}
                    onChange={state._updateField("date-date")}
                    type="date"
                  />
                  <input
                    className="date"
                    id="date-time"
                    // placeholder="2017-07-05T09:51:03.519Z"
                    value={date.time}
                    onChange={state._updateField("date-time")}
                    type="time"
                  />
                </div>
                <div className="field">
                  <label>End : </label>
                  <input
                    className="date"
                    id="datefin-date"
                    // placeholder="2017-07-05T09:51:03.519Z"
                    onChange={state._updateField("datefin-date")}
                    value={dateFin.date}
                    type="date"
                  />
                  <input
                    className="date"
                    id="datefin-time"
                    // placeholder="2017-07-05T09:51:03.519Z"
                    onChange={state._updateField("datefin-time")}
                    value={dateFin.time}
                    type="time"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={12} className="basics">

                <div className="field"
                  style={{ display: "flex", width: "100%", color: "grey" }}
                >
                  <label>Original date (not formatted) : </label>
                  <p style={{ marginLeft: "16px" }}>
                    {state.event.notFormattedDate
                      ? state.event.notFormattedDate
                      : ""}
                  </p>
                </div>

              </Grid>

              <Grid item xs={12} className="basics">
                <div className="bottom">
                  <div className="field">
                    <label>{this.fetchedAddress}</label>
                    <Map
                      value={state.event}
                      onChange={handleAddressChange}
                      type="address"
                      name="address"
                    />
                  </div>
                  <div className="field">
                    <label>Lat : </label>
                    <input
                      className="latlng"
                      type="text"
                      onChange={state._updateField("lat")}
                      value={state.event.coordinate.lat}
                    />
                    <label>Lng : </label>
                    <input
                      className="latlng"
                      type="text"
                      onChange={state._updateField("lng")}
                      value={state.event.coordinate.lng}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              {state.categoriesList ? (
                <Grid item xs={12}>
                  <label>Categories : </label>
                  <Select
                    id="categories1"
                    multiple={true}
                    value={state.event.categories.map(x => x.id)}
                  // onChange={state._updateField("categories")}
                  >
                    <option value="">
                      Select a Category
                        </option>
                    {state.categoriesList && state.categoriesList.map(a => {
                      return <option value={a.value || 1}>{a.name || "ERROR"}</option>
                    })}
                  </Select>
                </Grid>
              ) : null}
              <div className="field" style={{ width: "100%" }}>
                <label>TAGS : </label>
                <Tags updateEvent={state.updateEvent} currentEvent={state.event} />
              </div>
            </Grid>
            <div className="description" style={{ width: "100%" }}>
              <label>Description : </label>
              <CKEditor
                editor={ClassicEditor}
                data={state.event.description}
                onChange={state._updateEditor}
              />
            </div>
            <Grid item xs={12}>
              <div className="buttons">
                {state.event.archived !== true && <>
                  <Button
                    variant="contained"
                    style={{ margin: "10px", backgroundColor: "#FF595E" }}
                    startIcon={<DeleteIcon />}
                    onClick={() => state._deleteEvent(state.event.id)}
                  >
                    Delete
                    </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "10px", backgroundColor: "#118AB2" }}
                    onClick={() => state._seePrevNextEvent(-1)}
                  >
                    Prev
                    </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "10px", backgroundColor: "#8AC926" }}
                    onClick={() => state._validEvent(state.event)}
                  >
                    {this.props.type}
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "10px", backgroundColor: "#118AB2" }}
                    onClick={() => state._seePrevNextEvent(1)}
                  >
                    Next
                    </Button>
                </> || <></>
                }
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>);
  }
}
