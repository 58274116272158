import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { apiToken } from "./apiToken/reducers";
import { userData } from "./user/reducers";
import { eventData } from "./events/reducers";
import { errorMessage } from "./errorMessage/reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2
}

export const rootReducer = combineReducers({
  apiToken,
  userData,
  eventData,
  errorMessage,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const devTool =
  process.env.NODE_ENV === "development"
    ? (window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
    : compose;

// const saver = s => next => action => {
//   let stateToSave = s.getState();
//   sessionStorage.setItem("store", JSON.stringify({ ...stateToSave }));
//   return next(action);
// }


export const store = createStore(persistedReducer,
  compose(
    applyMiddleware(ReduxThunk),
    devTool
  ))
export const persistor = persistStore(store)

export default store;


// export const store = process.env.NODE_ENV === "development" ? createStore(persistedReducer,
//   compose(
//     applyMiddleware(ReduxThunk),
//     devTool
//   )) : createStore(persistedReducer,
//     applyMiddleware(ReduxThunk),
//   )

