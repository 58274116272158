import React from "react";
import {
    GoogleMap,
    useLoadScript,
    Marker,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";

import { makeStyles } from '@material-ui/core';

import "@reach/combobox/styles.css";

const libraries = ["places"];
const mapContainerStyle = {
    width: '40vw',
    height: '40vh',
    position: 'relative',
    border: '1px grey solid',
};
const options = {
    disableDefaultUI: true,
    zoomControl: true,
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(2),
    },
    geocodeContainer: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
    },
    search: {
        display: 'flex',
        width: '500px',
        '& label': {
            paddingTop: 20
        }
    },
    inputSearch: {
        height: '2em',
        border: 'none',
        borderBottom: '1px solid grey',
        fontSize: '1rem',
        marginBottom: '5px',
        marginLeft: -5,
        position: 'relative',
        top: 0,
        left: '1%',
    },
    div: {
        display: 'flex',
        '& p': {
            paddingTop: 30,
        }
    }
}));

export default function Map(props) {

    const handleInputChange = event => {
        // props.value.address = event.address
        props.onChange(event)
    }

    const classes = useStyles();
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries,
    });

    // Use states when you want a re-render, ref when using states without causing re-render
    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const goTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, [])

    if (loadError) return "Loading Error";
    if (!isLoaded) return "Loading...";

    return (
        <div className={classes.geocodeContainer}>
            <div className={classes.search}>
                <Search event={props.value} onChange={handleInputChange} goTo={goTo} />
            </div>
            <div className="map">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={13}
                    center={{ lat: props.value.coordinate.lat ? parseFloat(props.value.coordinate.lat) : "", lng: props.value.coordinate.lng ? parseFloat(props.value.coordinate.lng) : "" }}
                    options={options}
                    onLoad={onMapLoad}
                >
                    <Marker position={{ lat: parseFloat(props.value.coordinate.lat), lng: parseFloat(props.value.coordinate.lng) }} />
                </GoogleMap>
            </div>
        </div>
    )
}

export function Search(props) {

    const handleInputChange = event => {
        console.log('"'+props.event.address+'"','"'+event.target.value+'"')
        // props.event.address = event.target.value
        setValue(event.target.value, true);
        props.onChange({address:event.target.value})
        
    }

    const classes = useStyles();
    let currentEvent = props.value;

    const {
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'fr' },
        },
    });
    console.log("suggestions",status,data)

    return (
        <div className={classes.div}>
            <Combobox
                onSelect={async (address) => {
                    console.log("ADRESSE",address)
                    setValue(address);
                    
                    
                    // the false option come from Autocomplete, no need to fetch data again from API Places, we already know the address
                    // clearSuggestions();

                    try {
                        const results = await getGeocode({ address });
                        const { lat, lng } = await getLatLng(results[0]);
                        props.goTo({ lat, lng });
                        // currentEvent.lat = lat;
                        // currentEvent.lng = lng;
                        props.onChange({ lat, lng, address });
                    } catch (error) {
                        props.onChange({ address });
                    }

                }}
            >
                <ComboboxInput
                    className={classes.inputSearch}
                    type='adress'
                    value={props.event.address}
                    onChange={handleInputChange}
                    placeholder="Enter an address"
                />
                <ComboboxPopover style={{zIndex: 2000}}>
                    <ComboboxList>
                        {status === "OK" &&
                            data.map(({ place_id, description }) => (<ComboboxOption key={place_id} value={description} />))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    );
}