const setActiveApiToken = data => ({
  type: "SET_ACTIVE_APITOKEN",
  data
});

const isFetchingData = data => ({
  type: "IS_FETCHING_DATA",
  data
});

const resetApitoken = () => ({
  type: "RESET_API_TOKEN",
  undefined
});


export const mutation = {
  setActiveApiToken,
  isFetchingData,
  resetApitoken
};
