const setEventsMongo = data => ({
  type: "SET_SELECTED_EVENTS_MONGO",
  data
});

const setEventsSails = data => ({
  type: "SET_SELECTED_EVENTS_SAILS",
  data
});

export const mutation = {
  setEventsMongo,
  setEventsSails
};
  