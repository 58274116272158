import { initialState } from "./states";

export const apiToken = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_APITOKEN": {
      return Object.assign({}, state, {
        activeApiToken: action.data
      });
    }
    case "IS_FETCHING_DATA": {
      return Object.assign({}, state, {
        isFetching: action.data
      });
    }
    case "RESET_API_TOKEN" : {
      return initialState
    }
    default:
      return state;
  }
};
