import React, { useState, useEffect } from 'react';
import './PictureLoader.scss'

export default ({ defaultPicture, onChange }) => {
    const [file, setFile] = useState(defaultPicture)
    useEffect(() => { defaultPicture && setFile(defaultPicture) })
    const [text, setText] = useState("Choose Image")
    const defaultProps = {
        defaultPicture: undefined,
        onChange: console.log
    }
    const newImage = e => {
        try {
            const File = e.target.files[0];
            const FileURL = window.URL.createObjectURL(File)
            setFile(FileURL)
            try {
                onChange(FileURL, File)
            } catch (err) {
                console.log("Error on 'onChange'", err)
            }
        } catch (err) {
            setFile(undefined)
            setText("Error retreiving image")
        }
    }
    const inputFile = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.onchange = newImage
        fileSelector.click()
    }
    console.log(file)
    if (file) {
        // if ((type || "").startsWith("video")) {
        //     return <video
        //         className="component-picture-loader"
        //         src={file}
        //         onClick={inputFile}
        //         autoPlay
        //         ></video>
        // }
        return (<img
            className="component-picture-loader"
            src={file}
            onClick={inputFile} />)
    }
    return (<button
        className="no-image"
        type="button"
        onClick={inputFile}>
        {text}
    </button>)


}