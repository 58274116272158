import React, { Component, useRef } from 'react';
import EventsService from '../../services/EventsService'
import Pagination from '@material-ui/lab/Pagination';
import './eventList.scss'
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ListItemAvatar,
} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types'
import moment from 'moment';
import 'moment/locale/fr';
import store from '../../store'




class EventList extends Component {

  constructor(props) {
    super(props)
    this.state = props.getSave && props.getSave() || { currentFilters: this.defaultFilter() || {}, selectedEvents: [], count: 0, page: 1, perPage: 10 }
    // console.log("State", this.state, "getSave", props.getSave(), "saver", props.saver)
    this.fetching = false
  }

  static defaultProps = {
    EventSource: undefined,
    filters: undefined,
    open: undefined
  }

  static propTypes = {
    EventSource: PropTypes.func.isRequired,
    getSave: PropTypes.func,
    saver: PropTypes.func,
    open: PropTypes.func,
    filters: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
          ]),
          value: PropTypes.any.isRequired
        }).isRequired
      ).isRequired
    ).isRequired
  }

  defaultFilter = () => Object.entries(this.props.filters).reduce((sum, nen) => { sum[nen[0]] = nen[1][0].value; return sum; }, {})
  resetFilters = () => this.setState({ currentFilters: this.defaultFilter() })

  fetch = async (p) => {
    if (!this.fetching) {
      this.fetching = true
      const save = this.props.getSave && this.props.getSave() || {}
      // console.log("FETCH", save)
      const page = parseInt(p || save.page || 1)
      const perPage = parseInt(save.perPage || 10)
      let filters = { ...save.currentFilters } || {}
      save.currentFilters && (filters.categories = [filters.categories])
      console.log("EVENT LIST ===========", filters)
      await this.props.EventSource(filters, page, perPage).then(data => { this.props.saver({ ...save, events: data.rows, count: data.metaData.count, page: page }); return data })
      // console.log("FETCH", data)
      this.fetching = false
    }
  }

  select = (event) => {
    let selected = [...(this.props.getSave().selectedEvents || [])];
    if (selected.find(e => e.id === event.id)) {
      selected = selected.filter(e => e.id !== event.id)
    } else {
      console.log(this.props.getSave().selectedEvents)
      selected.push(event);
    }
    this.props.saver({ ...this.props.getSave(), selectedEvents: selected })
  }

  updateEvent = (event) => {
    console.log("UPDATE EVENT", event)
    let events = [...(this.props.getSave().events || [])]
    let index = events.findIndex(e => e.id === event.id)
    if (index !== -1) {
      events[index] = event;
      let selected = [...(this.props.getSave().selectedEvents || [])]
      let indexS = selected.findIndex(e => e.id === event.id)
      if (indexS !== -1) selected[indexS] = event
      this.props.saver({ ...this.props.getSave(), events, selectedEvents: selected })
    }
  }
  // selectInevitables = (event, inevitable, inevitableRate, published, chatbot) => {
  //   console.log("SELECT INEVITABLES")
  //   let events = [...(this.props.getSave().events || [])]
  //   let index = events.findIndex(e => e.id === event.id)
  //   if (index !== -1) {
  //     console.log("INEVITABLES", event.id, events[index].id, inevitable)
  //     events[index].inevitable = inevitable
  //     events[index].inevitableRate = inevitableRate
  //     events[index].published = published
  //     events[index].chatbot = chatbot
  //     let selected = [...(this.props.getSave().selectedEvents || [])]
  //     let indexS = selected.findIndex(e => e.id === event.id)
  //     if (indexS !== -1) selected[indexS].inevitable = inevitable
  //     this.props.saver({ ...this.props.getSave(), events, selectedEvents: selected })
  //   }
  // }
  isSelected = event => !!(this.props.getSave().selectedEvents || []).find(e => e.id === event.id)
  changePage = (e, i) => this.fetch(i)

  render() {
    // console.log("EVENTS", this.state.events || (this.props.getSave() && this.props.getSave().events))
    // console.log("FILTERS", this.props.filters)

    let evs = (this.props.getSave() || {}).events
    // console.log("COUNT -1",(this.props.getSave() || {}).count)
    if (!evs) {
      const data = this.fetch()
      if (data && data.rows && data.metaData && data.metaData.count) {
        evs = data.rows
        this.setState({ events: data.rows, count: data.metaData.count })
      }
    }
    const numberOfPages = !this.props.getSave() ? 0 : Math.ceil((this.props.getSave().count || 1) / (this.props.getSave().perPage || 10))
    return (<>
      <input
        defaultValue={((this.props.getSave && this.props.getSave() || {}).currentFilters || {})["searchByName"]}
        type="text" name="search" placeholder="Search event by name"
        onKeyUp={e => {
          if (e.key === 'Enter')
            this.fetch(1)
          else {
            let save = this.props.getSave() || { currentFilters: {} };
            !save.currentFilters && (save.currentFilters = {})
            save.currentFilters["searchByName"] = e.target.value;
            this.props.saver(save)
          }
        }}
      />
      <Card className={"eventlist-card"} >
        {Object.entries(this.props.filters).map(entry => {
          const filter = entry[0]
          const options = entry[1]
          return (<FormControl className={"form-control normal"} >
            <InputLabel id="demo-simple-select-label">{filter[0].toUpperCase() + filter.substr(1)}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={JSON.stringify(((this.props.getSave && this.props.getSave() || {}).currentFilters || {})[filter] || options[0].value)}
              onChange={
                (e) => {
                  let save = this.props.getSave() || { currentFilters: {} };
                  !save.currentFilters && (save.currentFilters = {})
                  save.currentFilters[filter] = JSON.parse(e.target.value);
                  this.props.saver(save)
                  this.fetch(1);
                }
              }
              defaultValue={(() => {
                JSON.stringify(this.state.currentFilters && this.state.currentFilters[filter] || options[0].value)
              })()}>
              <MenuItem value={JSON.stringify(options[0].value)}>{options[0].name}</MenuItem>
              {options.slice(1).sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1).map(crawl => <MenuItem key={crawl.name} value={JSON.stringify(crawl.value)}>{crawl.name}</MenuItem>)}
            </Select>
          </FormControl>)
        })
        }
        <Button
          onClick={() => {
            this.fetch();
          }}>Refresh</Button>
        <Button
          onClick={() => {
            this.resetFilters();
          }}>Remove Filters</Button>
      </Card>
      <Card className="card">
        <List
          component="ul"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" className={"header"}>
              <h6>{this.props.getSave() && this.props.getSave().count || 0} events</h6>
              <div>
                {
                  Object.entries(this.props.actions)
                    .map(a =>
                      <Button
                        onClick={async () => {
                          const refr = await a[1](this.props.getSave().selectedEvents)
                          refr !== false && this.fetch()
                        }}>
                        {a[0]}
                      </Button>)
                }
              </div>
            </ListSubheader>
          }
          className={"collection-item folder"}
        >
          <div className="list">
            {(evs && evs.length > 0) ?
              evs.map(event => {

                let note = 0;
                if (this.props.filters.note) {
                  let problems = [];
                  event.coordinate && event.coordinate.lat && event.coordinate.lng && note++ || problems.push('Lat/Lon')
                  event.datefin && event.date && note++ || problems.push('Dates');
                  (event.categories && event.categories.length) ? note++ : problems.push('Categories')
                  event.description && note++ || problems.push('Description')
                  event.place && note++ || problems.push('Place')
                  event.address && note++ || problems.push('Address')
                } else note = undefined
                event.note = note
                const EventRow = this.props.row || function () { return (<div>EVENT ROW MISSING</div>) }
                return (
                  <EventRow key={event.id} select={this.select} checked={this.isSelected(event)} event={event} open={this.props.open} updateEvent={this.updateEvent}/*inevitable={this.selectInevitables}*/ />
                )
              }) : (<div>NO EVENTS</div>)
            }
          </div>
        </List>
      </Card>
      {(evs && evs.length && numberOfPages > 1) ?
        (<Pagination
          className="pagination"
          count={numberOfPages}
          // page={this.props.getSave().page || 1}
          defaultPage={parseInt(this.props.getSave().page || 1)}
          siblingCount={1}
          onChange={this.changePage} />) :
        (<>Pagination {numberOfPages + " " + (evs ? evs.length : 0)}</>)
      }
    </>)
  }

}

export default EventList
