import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Compass as CompassIcon,
  CheckCircle as CheckCircleIcon,
  User as UserIcon,
  List as ListIcon,
  Plus as Plus
} from 'react-feather';
import NavItem from './NavItem';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"
import theme from '../../theme'
import {
  Menu,
  X
} from "react-feather"
import './navbar.scss'
const items = [
  {
    href: '/profile',
    icon: UserIcon,
    title: 'Profile'
  },
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/crawlers',
    icon: CompassIcon,
    title: 'Crawlers'
  },
  {
    href: '/eventValidation',
    icon: CheckCircleIcon,
    title: 'Validation Event'
  },
  {
    href: '/companyEvents',
    icon: ListIcon,
    title: 'Company Events'
  },
  {
    href: '/addEvent',
    icon: Plus,
    title: 'Add Event'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error'
  }
];
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = (prop) => {
  const classes = useStyles(theme);
  const [open, setOpen] = useState(true)
  const props = prop.userData || {}
  let bool = true
  try { bool = ["/crawlers", "/dashboard", "/", "/profile", "/eventValidation", "/companyEvents", "/addEvent"].includes(prop.location.pathname) } catch (err) { console.log(err) }
  if (!bool) return <></>
  return (
    <div
      className="navbar-drawer"
      style={{width: open ? drawerWidth+"px" : "auto"}}
    >
      <div style={{ position: "absolute", top: "80px", left: "24px", zIndex: "1300" }} onClick={() => setOpen(!open)}>
        {open ? <X /> : <Menu />}
      </div>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open={open}
        variant="persistent"
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            p={2}
          >
            <Avatar
              className={classes.avatar}
              src={''}
            />
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h5"
            >
              {(props.firstname + " " + props.lastname || "Crawler")}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {props.company && props.company.name + " - " + props.roles.slice(-8, -7) + props.roles.slice(-7, -3).toLowerCase()}
            </Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <List>
              {items.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </List>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default withRouter(connect(a => a)(NavBar));
