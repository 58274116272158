// import { ReactReduxContext } from 'react-redux';
import AuthService from '../../services/AuthService';
import { mutation } from "./mutations";

const handleError = err => {
  console.error("Error in apiToken action : ", err);
};

export const getApiToken = () => dispatch => {
    dispatch(mutation.isFetchingData(true))
    return AuthService.getToken()
    .then(result => {
      dispatch(mutation.setActiveApiToken(result));
      dispatch(mutation.isFetchingData(false));
      return result; 
    });
};

export const setActiveApiToken = data => dispatch => {
  try {
    dispatch(mutation.setActiveApiToken(data));
  } catch (err) {
    handleError(err);
  }
};