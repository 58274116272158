import React, { useRef } from "react";
import moment from "moment";
import Jimp from "jimp";

import { Snackbar } from "@material-ui/core";

import "./EventValidation.css";

import Page from "../../components/Page/Page";
import TopBar from "../../components/TopBar";
import NavBar from "../../components/NavBar/index";
import EventList from "../../components/EventList";
import EventFocusView from "../../components/EventFocusView";
import CategoriesService from "../../services/CategoriesService";
import PicturesService from "../../services/PicturesService";
import EventsService from "../../services/EventsService";
import SendingblueService from "../../services/SendingblueService";

import {
  MessageCircle,
  ArrowUpCircle
} from 'react-feather';

import MuiAlert from "@material-ui/lab/Alert";
import AuthService from "../../services/AuthService";
import * as Save from '../../store/events/actions'
import { connect } from "react-redux"
import { Helmet } from 'react-helmet-async'
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ListItemAvatar,
} from "@material-ui/core"
import Rating from '@material-ui/lab/Rating'
import InfoIcon from '@material-ui/icons/Info'
import PropTypes from 'prop-types'
import 'moment/locale/fr'
import store from '../../store'
import SendingBlueView from "../../components/SendingblueView/sendingblueview";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DEFAULT_NUMBER_PER_PAGE = 10;

class CompanyEvent extends React.Component {
  filters = () => {
    let origins = (this.state.origins || []).map(a => ({ name: a, value: a }))
    let filtered = origins.filter(e1 => e1.name != null)
    console.log(this.state)
    console.log(filtered)
    // filtered.push({name: null, value: null})

    return {
      origine: [{ name: "Tous", value: 0 }, ...filtered],
      categories: [{ name: "Toutes", value: 0 }, ...(this.state.categoriesList || [])],
      cities: [
        { name: "Toutes", value: { lat: 0.0, lng: 0.0 } },
        { name: "Paris", value: { lat: 48.8534, lng: 2.3488 } },
        { name: "Marseille", value: { lat: 43.296482, lng: 5.36978 } },
        { name: "Toulouse", value: { lat: 43.6043, lng: 1.4437 } },
        { name: "Nantes", value: { lat: 47.218371, lng: -1.553621 } },
        { name: "Montpelier", value: { lat: 43.6, lng: 3.8833 } },
        { name: "Strasbourg", value: { lat: 48.5734053, lng: 7.7521113 } },
        { name: "Bordeaux", value: { lat: 44.837789, lng: -0.57918 } },
        { name: "Lille", value: { lat: 50.6333, lng: 3.0667 } },
        { name: "Nice", value: { lat: 43.7031, lng: 7.2661 } }
      ],
      inevitable: [
        { name: "Tout", value: 0 },
        { name: "Contournable", value: "false" },
        { name: "Incontournable", value: "true" }

      ],
      distance: [
        { name: 20, value: 20 },
        { name: 50, value: 50 },
        { name: 100, value: 100 },
        { name: 150, value: 150 },
        { name: 200, value: 200 },
        { name: 500, value: 500 },
        { name: 1000, value: 1000 }
      ],
      ancienneté: [
        { name: "En cours", value: 1 },
        { name: "Terminés", value: 2 },
        { name: "Tout", value: 3 }
      ],
      ordre: [
        { name: "Asc", value: 2 },
        { name: "Desc", value: 1 },
      ]
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingEvents: true,
      origins: [],
      // events: [],
      // shownEvents: [],
      // currentEvent: null,
      // selectedEvents: [],
      totalEvents: 0,
      totalEventsCount: 0,

      // picture: null,
      // pictureCropped: null,
      // date: { start: '', end: '' },
      categoriesList: null,

      // showFilters: true,
      // activeFilter: false,
      // coordinatesFilter: { lng: 0.0, lat: 0.0 },
      // distanceFilter: 50,
      // noteFilter: 6,
      // noteFilterMode: 0,
      // categoryFilter: { value: '', children: "Toutes" },
      // oldFilter: 2,
      // orderFilter: 0,

      // indexEventPass: 0,

      // snackbarMessage: "",
      // snackbarOpen: false,

      // page: 1,
      // perPage: DEFAULT_NUMBER_PER_PAGE,
      // crawlers: [],
      // crawlerFilter: "TOUS",
      // cityValue: "TOUTES"
    };
    this.focusMode = false;
    this.setState = this.setState.bind(this);
    this._changeEvent = this._changeEvent.bind(this);
    this._updateAddress = this._updateAddress.bind(this);
    this._updateCategoriesSelect = this._updateCategoriesSelect.bind(this);
    // this._deleteEvent = this._deleteEvent.bind(this);
    this._seePrevNextEvent = this._seePrevNextEvent.bind(this);
    this._validEvent = this._validEvent.bind(this);
  }

  componentDidMount() {
    AuthService.check()
      .then(() => this._fetchOriginsNames(this.filters))
      .then(() => this._fetchCategories())
    // .then(() => this._fetchTotalEvents())
  }
  // async _fetchTotalEvents() {
  //   try {

  //     const totalEvents = await EventsService.getSailsEventsCount();
  //     this.setState({ totalEvents: totalEvents.metaData.count });
  //   } catch (err) {
  //     console.log("COULD'NT FETCH TOTAL EVENT")
  //   }
  // }

  async _fetchOriginsNames(olds=null) {
    try {
      const names = await EventsService.getOrigins(olds)

      this.setState({ origins: names });
    } catch (err) {
    }
  }

  async _fetchCategories() {
    try {
      const categories = await CategoriesService.getCategories();
      this.setState({ categoriesList: categories.rows.map(a => ({ name: a.name, value: a.id })) });
    } catch (err) {
    }
  }


  _changeDateWithEvent() {
    if (this.state.currentEvent) {
      let date = { start: [], end: [] }
      let theEvent = this.state.currentEvent;

      if (this.state.currentEvent.date) {
        if (this.state.currentEvent.date.date) {
          date.start.push(this.state.currentEvent.date.date);
          date.start.push(this.state.currentEvent.date.time);
        } else if (moment(this.state.currentEvent.date).isValid()) {
          date.start.push(moment(this.state.currentEvent.date).format("YYYY-MM-DD"))
          date.start.push(moment(this.state.currentEvent.date).format("HH:mm"))
        }
        theEvent.date = {
          date: date.start[0],
          time: date.start[1]
        }
      } else {
        theEvent.date = null;
      }

      if (this.state.currentEvent.datefin) {
        if (this.state.currentEvent.datefin.date) {
          date.end.push(this.state.currentEvent.datefin.date);
          date.end.push(this.state.currentEvent.datefin.time);
        } else if (this.state.currentEvent.datefin &&
          moment(this.state.currentEvent.datefin).isValid()) {
          date.end.push(moment(this.state.currentEvent.datefin).format("YYYY-MM-DD"))
          date.end.push(moment(this.state.currentEvent.datefin).format("HH:mm"))
        }
        theEvent.datefin = {
          date: date.end[0],
          time: date.end[1]
        }
      } else {
        theEvent.datefin = null;
      }

      this.setState(
        {
          date,
          currentEvent: theEvent
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  openSnackbar(msg = "", open = true) {
    this.setState({ snackbarMessage: msg, snackbarOpen: open });
  }
  openErrorSnackbar(msg = "", open = true) {
    this.setState({ snackbarMessage: msg, snackbarOpen: open, severity: "error" });
  }
  openSuccessSnackbar(msg = "", open = true) {
    this.setState({ snackbarMessage: msg, snackbarOpen: open, severity: "success" });
  }
  _updateCategoriesSelect(e) {
    let options = e.target.options;
    let value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    let currentEvent = this.state.currentEvent;
    currentEvent.categories = value;
    this.setState({ currentEvent });
  }

  _updateAddress = address => {
    this.setState({address: address })
  }

  _updateField = field => (e) => {
    let currentEvent = this.state.currentEvent;
    let value = e.target.value;

    if (field.match(/^date/g)) {

      if (field === "date-date") {
        if (currentEvent.date.date) {
          currentEvent.date.date = value
        } else {
          currentEvent.date = value + currentEvent.date.slice(10)
        }

      } else if (field === "date-time") {
        if (currentEvent.date.time) {
          currentEvent.date.time = value
        } else {
          currentEvent.date = currentEvent.date.replace(/T\d\d:\d\d/, "T" + value)
          console.log(currentEvent.date)
        }

      } else if (field === "datefin-date") {
        if (currentEvent.datefin.date) {
          currentEvent.datefin.date = value
        } else {
          currentEvent.datefin = value + currentEvent.datefin.slice(10)
        }
      } else if (field === "datefin-time") {
        if (currentEvent.datefin.time) {
          currentEvent.datefin.time = value
        } else {
          currentEvent.datefin = currentEvent.datefin.replace(/T\d\d:\d\d/, "T" + value)
        }
      }

    } else {
      currentEvent[field] = e.target.value;
    }
    this.setState({ currentEvent });
  }

  _deleteEventFromList() {
    this._fetchEvents(this.state.page, this.state.perPage);
  }

  async _updatePictureOnEventChange(event) {
    if (event) {
      try {
        const url = event.picture;
        const data = await PicturesService.getPictureSails(event.id)
        this.setState({
          picture: url,
          loadingEvents: false,
          currentEvent: event,
        },
          () => {
            this.forceUpdate();
          });
      } catch (err) {
        this.setState({
          picture: null,
          loadingEvents: false,
          currentEvent: event,
        },
          () => {
            this.forceUpdate();
          }
        );
      }
    }
  }

  _changeEvent(event, index = -1) {
    if (event) {
      this.setState(
        { currentEvent: event, indexEventPass: index > 0 ? index : 0 },
        () => {
          this._updatePictureOnEventChange(event);
          this._changeDateWithEvent();
          this.focusMode = true;
        }
      );
    }
  }

  _seePrevNextEvent(dir) {
    if (
      this.state.shownEvents &&
      this.state.shownEvents[this.state.indexEventPass + dir]
    ) {

      this.setState((prev) => {
        return {
          indexEventPass: prev.indexEventPass + dir,
          currentEvent: prev.shownEvents[prev.indexEventPass + dir]
        }
      },
        () => {
          this._changeDateWithEvent();
          this._updatePictureOnEventChange(this.state.currentEvent);

        });
    }
  }

  _validEvent = async (event) => {
    let cat = [...(document.querySelectorAll("#categories1 option") || [])].filter(a => a.selected)
    console.log(cat)
    cat = cat.map(a => a.value)
    let finalEvent = Object.assign({}, event);
    finalEvent.categories = cat;
    finalEvent.hasOwnProperty("note") && delete finalEvent.note
    try {
      // finalEvent.description = this.description
      let valid = true;

      [
        "categories",
        "description",
        "date",
        "datefin",
        "title",
        "place",
        "address",
        "coordinate"
      ].forEach(a=>{
        if(!finalEvent[a]){
          valid = false;
          this.openSnackbar(`Le champ ${a} est manquant`);
        }
      })
      if(!valid) return

      const date = finalEvent.date;
      const datefin = finalEvent.datefin;

      console.log(date, datefin)

      const offset = new Date().getTimezoneOffset() * 60000
      console.log(date)
      let nDate = moment(date)
      console.log(nDate)
      if (!nDate.isValid()) {
        nDate = moment(date.date + "T" + date.time + "Z")
        console.log(nDate)

        if (!nDate.isValid()) {
          valid = false;
          this.openErrorSnackbar(
            "Le format de la date (" + nDate + " | " + JSON.stringify(date) + ") est invalide"
          );
        }
      }

      // console.log(nDate)
      finalEvent.date = nDate._i + 'Z'
      // console.log(moment(finalEvent.date).format('LLLL'))
      // console.log(moment(finalEvent.date + 'Z').format('LLLL'))
      // console.log(finalEvent.date)

      let nDatefin = moment(datefin)
      if (!moment(datefin).isValid()) {
        nDatefin = moment(datefin.date + "T" + datefin.time + "Z")
        if (!nDatefin.isValid()) {
          valid = false;
          this.openErrorSnackbar(
            "Le format de la datefin (" + nDatefin + " | " + JSON.stringify(datefin) + ") est invalide"
          );
        }
      }
      finalEvent.datefin = nDatefin._i + 'Z'

      if (moment(finalEvent.datefin).isBefore(moment(finalEvent.date))) {
        valid = false;
        this.openErrorSnackbar(
          "La date de début ne peut pas dépasser la date de fin"
        );
      }

      if (!event.picture)
        valid = false
      if (valid) {
        console.log(event.picture, event.pictureUrl)
        Jimp.read(
          event.picture
        )
          .then((picture) => {
            console.log("STEP II", picture)
            if (
              finalEvent.origin &&
              finalEvent.origin.toLowerCase().startsWith("evensi")
            ) {
              finalEvent.picture = picture;
              return Jimp.read("./static/images/EVENSI.png");
            } else {
              return picture
                .resize(680, 340)
                .quality(100)
                .getBase64Async(Jimp.MIME_JPEG);
            }
          })
          .then((picture) => {
            console.log("STEP III", picture)

            if (
              finalEvent.origin &&
              finalEvent.origin.toLowerCase().startsWith("evensi")
            ) {
              picture.resize(Jimp.AUTO, 20);
              const xOffset = 680 - picture.bitmap.width - 10;
              const yOffset = 340 - picture.bitmap.height - 10;
              return finalEvent.picture
                .resize(680, 340)
                .composite(picture, xOffset, yOffset)
                .quality(90)
                .getBase64Async(Jimp.MIME_JPEG);
            } else {
              return picture;
            }
          })
          .then((picture) => {
            finalEvent.picture = picture;
            console.log("réussite =================", finalEvent)
            return EventsService.updateEvent(finalEvent);
          })
          .then(() => {
            this.setState({ currentEvent: undefined, loadingEvents: undefined, totalEvents: undefined, totalEventsCount: undefined });
            this.openSuccessSnackbar("Event update succeeded");
          })
          .catch((err) => {
            this.openErrorSnackbar("Event update failed");
            console.error(err);
          });
      }
    } catch (error) {
      console.error(error)
    }
  }

  deleteEvent = async eventId => {
    console.log("DELETE EVENT")
    try {
      await EventsService.deleteSailsEvent(eventId)
      this.focusMode = false; this.forceUpdate(() => { this.openSuccessSnackbar("Event Deleted"); });
    } catch (err) {
      this.openErrorSnackbar("Couldn't delete event");
    }
  }
  deleteEvents = async eventId => {
    console.log("DELETE EVENTS")
    try {
      await EventsService.deleteSelectedEvents(eventId)
      this.focusMode = false; this.forceUpdate(() => { this.openSuccessSnackbar("Event Deleted"); });
    } catch (err) {
      this.openErrorSnackbar("Couldn't delete event");
    }
  }

  inevitable = async (event, rate = 5) => {
    let events = Array.isArray(event) ? event : [events]
    return await Promise.all(events.map(a => EventsService.putSailsInevitable(a.id, rate))).then(() => {
      this.openSuccessSnackbar("Event Updated")
    }).catch(err => {
      console.error(err)
      this.openSuccessSnackbar("Couldn't update Event")
    })
  }

  updatePublished = async (event, bool) => {
    let events = Array.isArray(event) ? event : [events]
    return await Promise.all(events.map(a => EventsService.putSailsPublished(a.id, bool))).then(() => {
      this.openSuccessSnackbar("Event Updated")
    }).catch(err => {
      console.error(err)
      this.openSuccessSnackbar("Couldn't update Event")
    })
  }
  // gen =
  sendingblue = async events => {
    // TODO
    console.log(events)
    SendingblueService.fetch().then(data =>{
      this.setState({
        sendingbluemode: true,
        sendingblueEvents: events,
        sendingblueData: data
      })
    })

    return false
  }
  actions = {
    CAMPAIGN: this.sendingblue,
    DELETE: this.deleteEvents
  }
  filters = () => {
    let origins = (this.state.origins || []).map(a => ({ name: a, value: a }))
    let filtered = origins.filter(e1 => e1.name != null)
    console.log(this.state)
    console.log(filtered)
    // filtered.push({name: null, value: null})

    return {
      origine: [{ name: "Tous", value: 0 }, ...filtered],
      categories: [{ name: "Toutes", value: 0 }, ...(this.state.categoriesList || [])],
      cities: [
        { name: "Toutes", value: { lat: 0.0, lng: 0.0 } },
        { name: "Paris", value: { lat: 48.8534, lng: 2.3488 } },
        { name: "Marseille", value: { lat: 43.296482, lng: 5.36978 } },
        { name: "Toulouse", value: { lat: 43.6043, lng: 1.4437 } },
        { name: "Nantes", value: { lat: 47.218371, lng: -1.553621 } },
        { name: "Montpelier", value: { lat: 43.6, lng: 3.8833 } },
        { name: "Strasbourg", value: { lat: 48.5734053, lng: 7.7521113 } },
        { name: "Bordeaux", value: { lat: 44.837789, lng: -0.57918 } },
        { name: "Lille", value: { lat: 50.6333, lng: 3.0667 } },
        { name: "Nice", value: { lat: 43.7031, lng: 7.2661 } },
        { name: "Lyon", value: { lat: 45.764043, lng:4.835659 } },
      ],
      inevitable: [
        { name: "Tout", value: 0 },
        { name: "Contournable", value: "false" },
        { name: "Incontournable", value: "true" }

      ],
      distance: [
        { name: 20, value: 20 },
        { name: 50, value: 50 },
        { name: 100, value: 100 },
        { name: 150, value: 150 },
        { name: 200, value: 200 },
        { name: 500, value: 500 },
        { name: 1000, value: 1000 }
      ],
      ancienneté: [
        { name: "En cours", value: 1 },
        { name: "Terminés", value: 2 },
        { name: "Tout", value: 3 }
      ],
      ordre: [
        { name: "Asc", value: 2 },
        { name: "Desc", value: 1 },
      ]
    }
  }

  render() {
    const _updateEditor = (event, editor) => {
      const data = editor.getData();
      this.setState((prevState) => ({
        currentEvent: {
          ...prevState.currentEvent,
          description: data
        }
      }));
    }
    return (
      <Page className={"page " + (this.state.loadingEvents ? "load" : "")} title="Events">
        <Helmet>
          <title>Crawlosaure - Events</title>
        </Helmet>
        <div className="section-title">
          <h2>Company Event</h2>
          {this.state.loadingEvents ? null : (
            <span className="totalEventsToValidate">
              {this.state.totalEvents + " event" + (this.state.totalEvents > 1 ? "s" : "")}
            </span>
          )}
        </div>

        <EventList
          type="companyEvent"
          filters={this.filters()}
          EventSource={async (filters, page, perPage) => await AuthService.check().then(() => EventsService.getSailsEvents(
            Object.assign({ ancienneté: 1, ordre: 2 },
              filters,
              {
                page: page.toString(),
                perPage: perPage.toString()
              })
          ))}
          saver={this.props.save}
          getSave={() => this.props.saveLocation}
          open={e => { this.focusMode = true; this.setState({ currentEvent: e }) }}
          actions={this.actions}
          row={EventRow}
        />

        {this.state.currentEvent && this.focusMode &&
          <EventFocusView
            origin={this.state.origins}
            type={"Update Event"}
            _updateEditor={_updateEditor}
            _updateField={this._updateField}
            _updateAddress={this._updateAddress}
            loadingEvents={this.state.loadingEvents}
            event={this.state.currentEvent}
            onClose={() => { this.focusMode = false; this.forceUpdate(); }}
            _seePrevNextEvent={(i) => this._seePrevNextEvent(i)}
            _deleteEvent={this.deleteEvent}
            _validEvent={this._validEvent}
            categoriesList={this.state.categoriesList}
            updateEvent={this.setState}
          />}
        {this.state.sendingbluemode && this.state.sendingblueEvents && <SendingBlueView
          // TODO
          onClose={() => this.setState({
            sendingbluemode: false,
          })}
          events={this.state.sendingblueEvents}
          contactList={this.state.sendingblueData.contactList}
          templates={this.state.sendingblueData.templates}
        />}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={() => this.openSnackbar("", false)}
          message={this.state.snackbarMessage}
          severity="error"
        >
          <Alert onClose={() => this.openSnackbar("", false)} severity="error">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </Page>
    );
  }
}

export default connect(a => ({ saveLocation: a.eventData.eventLists.sails }), { save: Save.setEventsSails })(CompanyEvent)

const EventRow = (props) => {
  const rstore = store.getState();
  // const [value, setValue] = React.useState(0);

  EventRow.defaultProps = {
    select: console.log,
    event: {},
  }

  EventRow.propTypes = {
    checked: PropTypes.bool,
    info: PropTypes.string,
    event: PropTypes.object.isRequired,
    select: PropTypes.func,
  }

  const handleCheckbox = e => {
    e.stopPropagation();
    return props.select(props.event)
  }

  const DefaultPicture = 'https://i.ibb.co/Yh2NX6Z/Time-N-Joy.png'
  let open = useRef()
  // console.log("ROWX",props.event, props.event.inevitable)
  return (
    <ListItem button className="eventRow" onClick={() => props.open && props.open(props.event)} container>
      <ListItemIcon>
        <Checkbox checked={props.checked}
          onClick={handleCheckbox} />
      </ListItemIcon>
      <ListItemAvatar className="image">
        <img src={props.event.picture} alt="event poster" onError={e => (e.target.src = DefaultPicture)} />
      </ListItemAvatar>


      <ListItemText className="text title" primary={
        <>
          {props.event.title.substring(0, 30)}
          <br />
          {props.event.categories.slice(0,2).map(x => x.name).join(", ")}
          <br />
          {props.event.origin}          
        </>
      } />

      <ListItemText primary={props.event.date ? moment(props.event.date).format('lll') : "NO DATE"} secondary={props.event.datefin ? moment(props.event.datefin).format('lll') : "NO DATE"}/>

      <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>

        <ArrowUpCircle />
        <Checkbox color="default" checked={props.event.published || false} onClick={() => EventsService.putSailsPublished(props.event.id, !props.event.published).catch(console.error).finally(() => {
          props.event.published = !props.event.published
          props.updateEvent(props.event)
        })} />

        <MessageCircle />
        <Checkbox color="default" checked={props.event.chatbot || false} onClick={() => EventsService.putSailsChat(props.event.id, !props.event.chatbot).catch(console.error).finally(() => {
          props.event.chatbot = !props.event.chatbot
          props.updateEvent(props.event)
        })} />

        <Rating value={props.event.inevitableRate || 0} onChange={(e, newValue) => EventsService.putSailsInevitable(props.event.id, newValue).catch(console.error).finally(() => {
          props.event.inevitable = !!newValue
          props.event.inevitableRate = newValue
          props.updateEvent(props.event)
        })} />

        <IconButton edge="end" onClick={(e) => { open = e.currentTarget; console.log(Boolean(open), open); }} ref={open}>
          <InfoIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>)
}
