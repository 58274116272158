import { ApiMongo } from './config/ApiMongoRequest';
import { ApiSails } from './config/ApisailsRequest'

export default {
    getPicture(pictureId) {
        return ApiMongo.get(`/pictures/${pictureId}`, {});
    },

    getPictureSails(pictureId) {
        return ApiSails.get(`/event/${pictureId}/getImage`, {});
    }
}
