import React from 'react'
import TopBar from '../../components/TopBar'
import Page from '../../components/Page/Page'
import NavBar from '../../components/NavBar/index'
import store from '../../store'
import { Helmet } from "react-helmet-async"
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  title: {
    display: 'flex',
    fontFamily: 'Roboto',
    lineHeight: '2.2em',
    fontWeight: 300,
  },
}));

export default function Home(props) {
  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Helmet>
        <title>Crawlosaure - Dashboard</title>
      </Helmet>
      <h2 className={classes.title}>Home Page</h2>
    </Page>
  )
}
