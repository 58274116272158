import { ApiSails } from "./config/ApisailsRequest"

const getTemplate = () => {
    return ApiSails.get("/sendinblue/templates?templateStatus=true")
}
const getContactList = () => {
    return ApiSails.get("/sendinblue/contactsLists")
}
const fetch = async () => {
    let fetched = {}
    await getTemplate()
        .then(res => {
            console.log("TEMPLATE", res)
            fetched.templates = res
            return getContactList()
        })
        .then(res => {
            console.log("CONTACTS", res)
            fetched.contactList = res
        })
        .catch(err => {
            console.error(err)
            fetched = undefined
        })
    return fetched
}
const send = async function ({
    campaign_name,
    campaign_subject,
    list_id,
    template_id,
    events_id,
    scheduled_at,
    sender_name,
    sender_email,
    reply_to
}) {
    if (
        !campaign_name ||
        !campaign_subject ||
        !list_id ||
        !template_id ||
        !events_id ||
        !scheduled_at
    )
        return undefined
    const body = {
        campaign_name,
        campaign_subject,
        list_id,
        template_id,
        events_id,
        scheduled_at,
        sender_name: sender_name || null,
        sender_email: sender_email || null,
        reply_to :reply_to || null
    }

    console.log(body)
    return ApiSails.post("/sendinblue/sendCampaign", body)
}
export default {
    fetch,
    send
}