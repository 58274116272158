import { ApiMongo } from './config/ApiMongoRequest';

export default {
  getCrawlers() {
    return ApiMongo.get('/crawlers', {});
  },

  executeCrawler(crawlerName, category) {
    return ApiMongo.post('/crawlers/' + crawlerName + '?category=' + category, {});
  },
}
