import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
// import { clearLocal } from './helpers/utils';

import {
  BrowserRouter as Router,
  // Route,
  Switch
} from "react-router-dom";
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { HelmetProvider } from 'react-helmet-async';
import Login from './views/login/Login';
import Profile from './views/profile/Profile';
import Dashboard from './views/dashboard/Dashboard';
import Crawlers from './views/crawlers/Crawlers';
import NotFound from './views/notfound/NotFound';
import AddEvent from './components/Add Event/addEvent'
import EventValidation from './views/eventsValid/EventValidation';
import CompanyEvents from './views/companyEvent/CompanyEvent';
import TopBar from './components/TopBar'
import NavBar from './components/NavBar/index'
import store from './store';
import { Provider } from 'react-redux';

const App = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Router>
            <TopBar />
            <NavBar />
            <Switch>
              <PublicRoute restricted={true} component={Login} path="/login" exact />
              <PrivateRoute component={Dashboard} path={['/', '/dashboard']} exact />
              <PrivateRoute exact path='/crawlers' component={Crawlers} />
              <PrivateRoute exact path='/profile' component={Profile} />
              <PrivateRoute exact path='/eventValidation' component={EventValidation} />
              <PrivateRoute exact path='/companyEvents' component={CompanyEvents} />
              <PrivateRoute path='/addEvent' component={AddEvent} />
              <PrivateRoute path='*' component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
