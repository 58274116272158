import React from 'react';
import { Link as RouterLink,
         useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { LogOut as LogOutIcon } from 'react-feather';
import Logo from './Logo';
import { isLogin, logOut } from '../helpers/utils'

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: 64,
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    // justifyContent: 'space-evenly',
  },
  topTitle: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 40,
    margin:0,
    justifySelf: 'center'
  },
  logo: {
    height: '50px',
    verticalAlign: 'bottom',
    justifySelf: 'flex-start'
  },
  icon: {
    marginRight: theme.spacing(1),
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    justifySelf: 'flex-end'
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo}/>
        </RouterLink>
        <h1 className={classes.topTitle}>Crawlosaure</h1>
        {isLogin() ? <button 
                        className={classes.icon} 
                        onClick={() => logOut(history)}>
                          <LogOutIcon size='20'/>
                      </button>
                      : <button style={{backgroundColor: 'blue', border: 'none'}}/>}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
