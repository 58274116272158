import React from "react";

import './eventFocusView.scss'
import { Button, Grid } from "@material-ui/core";
import { Select } from "react-materialize";


import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import moment from "moment";
import Timezone from "moment-timezone"
import Jimp from "jimp";

import Page from "../../components/Page/Page";
import Topbar from "../../components/TopBar"
import NavBar from "../../components/NavBar"

import AuthService from "../../services/AuthService";
import CategoriesService from "../../services/CategoriesService";

import { clearLocal } from '../../helpers/utils';
import Tags from '../Tags';
import Map from "../Map";
import EventsService from "../../services/EventsService";

import { connect } from 'react-redux';
import PictureLoader from "../PictureLoader"

export default connect(a => a)(class addEvent extends React.Component {

  constructor(props) {
    super(props);
    // const date = new Date()
    // const datefin = new Date(Date.now() + 93600000)
    let company = "0"
    try{
      company = this.props.userData.company.id
    }catch(err){
      console.log(err)
    }
    this.state = {
      date: {
        date: undefined, //date.toISOString().substr(0, 10),
        time: undefined //date.toLocaleTimeString(),
      },
      datefin: {
        date: undefined, //datefin.toISOString().substr(0, 10),
        time: undefined //datefin.toLocaleTimeString(),
      },
      place: "",
      title: "",
      tags: [],
      picture: "",
      description: "",
      // price_order_id: "",
      company,
      address: "",
      coordinate: {
        lat: "",
        lng:""
      },
      categories: [],
      categoriesList: null,
      url_ticketing: "",
      site: ""
    }
    this.picture = undefined;
    this.pictureUrl = undefined;
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    AuthService.check()
      .then(() => this._fetchCategories())
  }

  async _fetchCategories() {
    let categoriesList = [];
    try {
      const categories = await CategoriesService.getCategories();
      categories.rows.forEach(aCategory => {
        categoriesList.push(
          <option key={aCategory.name} value={aCategory.id}>
            {aCategory.name}
          </option>
        );
      })
      this.setState({ categoriesList: categoriesList });
    } catch (err) {
    }
  }

  
  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let a = this.state;
    (/^date[^\.]*\.[^\.]+$/.test(name)) ? (a[name.split(".")[0]][name.split(".")[1]] = value) : (a[name] = value)
    console.log(a)
    this.setState(a);
  }

  _validEvent = async (event) => {
    event.site = event.url_ticketing
    let cat = [...(document.querySelectorAll("#categories1 option") || [])].filter(a => a.selected)
    cat = cat.map(a => a.value)
    this.openSnackbar = console.log
    let finalEvent = Object.assign({}, event);
    // window.alert(JSON.stringify(finalEvent))
    console.log("VALID EVENT ++++++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log(event, finalEvent)
    try {
      finalEvent.categories = cat;
      finalEvent.description = this.description
      let valid = true;
      [
        "categories",
        "description",
        "date",
        "datefin",
        "title",
        "place",
        "address",
        "coordinate"
      ].forEach(a=>{
        if(!finalEvent[a]){
          valid = false;
          this.openSnackbar(`Le champ ${a} est manquant`);
        }
      })
      if(!valid) return

      const date = finalEvent.date;
      const datefin = finalEvent.datefin;
      finalEvent.origin = "Crawlosaure"

      let nDate = moment(date)
      if (!nDate.isValid()) {
        nDate = moment(date.date + "T" + date.time + "Z")
        if (!nDate.isValid()) {
          valid = false;
          this.openSnackbar(
            "Le format de la date (" + nDate + " | " + JSON.stringify(date) + ") est invalide"
          );
        }
      }
      finalEvent.date = nDate.toISOString()

      let nDatefin = moment(datefin)
      if (!moment(datefin).isValid()) {
        nDatefin = moment(datefin.date + "T" + datefin.time + "Z")
        if (!nDatefin.isValid()) {
          valid = false;
          this.openSnackbar(
            "Le format de la datefin (" + nDatefin + " | " + JSON.stringify(datefin) + ") est invalide"
          );
        }
      }
      finalEvent.datefin = nDatefin.toISOString()

      if (!moment(finalEvent.date).isBefore(moment(finalEvent.datefin))) {
        valid = false;
        this.openSnackbar(
          "La date de début ne peut pas dépasser la date de fin"
        );
      }

      if (!this.picture)
        valid = false
      console.log(this.picture, valid)
      if (valid) {
        console.log(this.picture, this.pictureUrl)
        Jimp.read(
          this.picture
        )
          .then((picture) => {
            console.log("STEP II", picture)
            if (
              finalEvent.origin &&
              finalEvent.origin.toLowerCase().startsWith("evensi")
            ) {
              finalEvent.picture = picture;
              return Jimp.read("./static/images/EVENSI.png");
            } else {
              return picture
                .resize(680, 340)
                .quality(100)
                .getBase64Async(Jimp.MIME_JPEG);
            }
          })
          .then((picture) => {
            console.log("STEP III", picture)

            if (
              finalEvent.origin &&
              finalEvent.origin.toLowerCase().startsWith("evensi")
            ) {
              picture.resize(Jimp.AUTO, 20);
              const xOffset = 680 - picture.bitmap.width - 10;
              const yOffset = 340 - picture.bitmap.height - 10;
              return finalEvent.picture
                .resize(680, 340)
                .composite(picture, xOffset, yOffset)
                .quality(90)
                .getBase64Async(Jimp.MIME_JPEG);
            } else {
              return picture;
            }
          })
          .then((picture) => {
            finalEvent.picture = picture;
            console.log("réussite =================", finalEvent)
            return EventsService.addEvent(finalEvent);
          })
          .then(() => {
            this.setState(Object.fromEntries(Object.entries(this.state).map(a=>{let b = a; b[1]="";return b})));
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return (
      <Page className={"page addEvent" + (this.state.loadingEvents ? "load" : "")} title="Add Event">

        {clearLocal()}

        <Grid direction="row" justify="space-between" alignItems="center">
          <Grid className="top-title" style={{ marginTop: "15px" }}>
            <h2>Add Event</h2>
          </Grid>
        </Grid>


        <form className="card">
          <Grid container justify="flex-start" alignItems="center">
            <Grid item xs={6} className="picture">
              <label>Image:</label>
              <PictureLoader onChange={(e,f) => f.type.startsWith("image") && (this.picture = e)} name="picture" id="image" />
              {/* <input
                type="file"
                onChange={e => this.picture = window.URL.createObjectURL(e.target.files[0])}
                name="picture"
                id="image"
              /> */}
            </Grid>

            <Grid item xs={6} className="first">
              <label>Title : </label>
              <input
                className="input_f"
                id="title"
                name="title"
                type="text"
                value={this.state.title}
                onChange={this.handleInputChange}
              />

              <label>Place : </label>
              <input
                className="input_f"
                type="text"
                name="place"
                value={this.state.place}
                onChange={this.handleInputChange}
              />

              <div className="field">
                <label>Start : </label>
                <input
                  type="date"
                  name="date.date"
                  className="date"
                  id="date-date"
                  value={this.state.date.date}
                  onChange={this.handleInputChange}
                />
                <input
                  className="date"
                  name="date.time"
                  id="date-time"
                  placeholder=""
                  type="time"
                  value={this.state.date.time}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="field">
                <label>End : </label>
                <input
                  className="date"
                  name="datefin.date"
                  id="datefin-date"
                  type="date"
                  value={this.state.datefin.date}
                  onChange={this.handleInputChange}
                />
                <input
                  className="date"
                  name="datefin.time"
                  id="datefin-time"
                  type="time"
                  value={this.state.datefin.time}
                  onChange={this.handleInputChange}
                />
                <label>URL CLARuSSE : </label>
                <input
                  className="input_f"
                  name="url_ticketing"
                  id="datefin-time"
                  type="text"
                  value={this.state.url_ticketing}
                  onChange={this.handleInputChange}
                />
              </div>
            </Grid>
          </Grid>
          <div className="bottom">
            <div className="field">
              <label>Address :</label>
              <Map
                name="address"
                value={this.state}
                onChange={a => { let event = a; console.log(a) ; delete a.event; this.setState({ ...a, coordinate: {lat: a.lat, lng: a.lng}  }) }}
                type="address"
              />
            </div>
          </div>
          <Grid container>
            {this.state.categoriesList ? (

              <Grid item xs={12}>
                <label>Categories : </label>
                <Select
                  id="categories1"
                  multiple={true}
                  name="categories"
                >
                  <option value="" disabled>
                    Select a Category
                        </option>
                  {this.state.categoriesList}
                </Select>
              </Grid>
            ) : null}
            <div className="field" style={{ width: "100%" }}>
              <label>TAGS : </label>
              <Tags updateEvent={this.setState} currentEvent={this.state} />
            </div>
          </Grid>

          <div className="description" style={{ width: "100%" }}>
            <label>Description : </label>
            <CKEditor
              editor={ClassicEditor}
              type="text"
              id="ckeditor"
              name="description"
              // value={this.state.description}
              onChange={(e, b) => (this.description = b.getData())}
            />
          </div>

          <div className="buttons">

            <Button
              variant="contained"
              type="submit"
              style={{ margin: "10px", backgroundColor: "#3F51B5", color: "white" }}
              onClick={e => { e.preventDefault(); this._validEvent(this.state) }}
            >
              Submit
                    </Button>

          </div>
        </form>
      </Page>
    );
  }
})