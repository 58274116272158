import React from 'react';
import { Link } from 'react-router-dom'

import './NotFound.scss';

class NotFound extends React.Component {
    render() {
        return (
            <div className="notFound">
                <div className="errorContainer">
                    <div className="errorCode">404</div>
                    <div className="errorMessage">This page doesn't exist </div>
                    <Link to='/'><div className="back waves-effect waves-dark btn">GO TO HOMEPAGE</div></Link>
                </div>
            </div>
        );
    }
}

export default NotFound;